import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import JasonPNG from '#assets/layout/jason-goodman.webp';
import CherrydeckPNG from '#assets/layout/cherrydeck.webp';
import SalesSolutionsPNG from '#assets/layout/linkedin-sales-solutions.webp';
import { CircleGraphic, Icon, GreenBadge } from '#components/Index';

interface Props {
  children?: React.ReactNode;
  header: React.ReactNode;
  subHeader: string;
}
const Layout: FC<Props> = ({ children, header, subHeader }) => {
  return (
    <>
      <Helmet>
        <title>Hiperpool - Sign Up</title>
        <meta
          name="description"
          content="Join Hiperpool to access job opportunities and all our career-boosting resources. Membership is 100% free."
        />
      </Helmet>

      <div className="relative isolate bg-hpblack text-white min-h-full-content h-full overflow-hidden">
        <CircleGraphic className="absolute h-72 w-auto left-0 -bottom-12 -z-10">
          <CircleGraphic.Half x={1} y={2} color="green-900" rotate={-90} />
          <CircleGraphic.Quarter x={2} y={2} color="green-900" rotate={90} />
          <CircleGraphic.Quarter x={3} y={2} color="green-500" rotate={180} />
          <CircleGraphic.Quarter x={1} y={3} color="green-500" rotate={-90} />
          <CircleGraphic.Quarter x={2} y={3} color="green-500" />
          <CircleGraphic.Half x={4} y={3} color="green-900" rotate={180} />
        </CircleGraphic>

        <div className="flex flex-col xl:flex-row w-full pt-12 xs:pb-12 xl:py-20 xl:px-0 gap-x-10 gap-y-14 m-auto max-w-6xl">
          <div className="basis-1/2 max-xl:mx-variable-lg -z-10">
            <h1 className="font-serif text-display-md lg:text-display-2xl">
              {header}
              <span className="text-green-500">.</span>
            </h1>

            <p className="text-md lg:text-xl lg:mb-12 mt-6 lg:max-w-md">{subHeader}</p>

            <div
              className="relative isolate h-[300px] w-[480px] max-lg:hidden md:max-xl:absolute md:max-xl:right-[80px] overflow-hidden"
              aria-hidden="true"
            >
              <img
                loading="eager"
                className="absolute rounded-full left-[80px]"
                src={SalesSolutionsPNG}
                height="157"
                width="157"
              />
              <div className="absolute z-10 top-[130px] left-[20px]">
                <GreenBadge>
                  <Icon.Notification size={3.5} className="text-green-600" />
                  Match found
                </GreenBadge>
              </div>
              <img
                loading="eager"
                className="absolute rounded-full left-[230px] top-[100px]"
                src={CherrydeckPNG}
                height="105"
                width="105"
              />
              <div className="absolute z-10 top-[180px] left-[290px]">
                <GreenBadge>
                  <Icon.CalendarChecked size={3.5} className="text-green-600" />
                  Interview scheduled
                </GreenBadge>
              </div>
              <img
                loading="eager"
                className="absolute rounded-full top-[170px] left-[120px]"
                src={JasonPNG}
                height="125"
                width="125"
              />
              <div className="absolute z-10 top-[260px] left-[190px]">
                <GreenBadge>
                  <Icon.CheckboxCircle size={3.5} className="text-green-600" />
                  Position filled
                </GreenBadge>
              </div>
            </div>
          </div>

          <div className="basis-1/2 max-w-xl xl:max-w-full max-lg:self-center xs:mx-variable-lg xl:mx-0 bg-gray-50 text-hpblack xs:rounded-lg flex flex-col gap-6 p-10 max-xs:px-variable-lg max-xs:py-16 items-center self-start">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
