import * as apiGetIndustryTypes from '#api/endpoints/onboarding/industryTypes';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  industryTypes = 'industry-types',
}

type GetApiPayload = apiGetIndustryTypes.Type['success']['payload'];
export const useGetIndustryTypes = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.industryTypes],
    queryFn: async () => await apiGetIndustryTypes.request(),
    ...options,
  });
