import React, { FC, ReactNode, createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { KeysEnum as ProfilePreferencesKeys } from '../data/ProfilePreferences';
import { Icon, Button, Modal, ProgressBar } from '#components/Index';

const queryKeys = [ProfilePreferencesKeys.talents, ProfilePreferencesKeys.profile, ProfilePreferencesKeys.preferences];

const defaultStepCallback = async (): Promise<boolean> => {
  return false;
};

export interface QuestionGroupModalProps {
  isOpen: boolean;
  close: () => void;
}

export interface Step {
  name: string;
  content: () => JSX.Element;
  visible: boolean;
}

interface StepsContextType {
  steps: Step[];
  setSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  nextDisabled: boolean;
  setNextDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  stepCallback: () => Promise<boolean>;
  setStepCallback: React.Dispatch<React.SetStateAction<() => Promise<boolean>>>;
  setStepVisibility: (stepName: string, visibility: boolean) => void;
  isStepVisible: (stepName: string) => boolean;
}

const StepsContext = createContext<StepsContextType | undefined>(undefined);

export const StepsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepCallback, setStepCallback] = useState<() => Promise<boolean>>(() => defaultStepCallback);
  const [nextDisabled, setNextDisabled] = useState(true);

  const setStepVisibility = (stepName: string, visibility: boolean) => {
    setSteps((prevSteps) => {
      return prevSteps.map((step) => {
        if (step.name === stepName) {
          return { ...step, visible: visibility };
        }

        return step;
      });
    });
  };

  const isStepVisible = (stepName: string) => {
    return steps.find((step) => step.name === stepName)?.visible ?? false;
  };

  const value = {
    steps,
    setSteps,
    currentStep,
    setCurrentStep,
    nextDisabled,
    setNextDisabled,
    stepCallback,
    setStepCallback,
    setStepVisibility,
    isStepVisible,
  };

  return <StepsContext.Provider value={value}>{children}</StepsContext.Provider>;
};

export const useSteps = () => {
  const context = useContext(StepsContext);

  if (context == null) {
    throw new Error('useSteps must be used within StepsProvider');
  }

  return context;
};

interface BaseProfileEditModalProps {
  initialSteps: Step[];
  isOpen: boolean;
  close: () => void;
}

export const BaseProfileEditModal: FC<BaseProfileEditModalProps> = ({ initialSteps = [], isOpen, close }) => {
  const queryClient = useQueryClient();
  const { steps, setSteps, setCurrentStep, currentStep, nextDisabled, setNextDisabled, stepCallback, setStepCallback } =
    useSteps();

  useEffect(() => {
    if (steps.length === 0) {
      setSteps(initialSteps);
    }
  }, [initialSteps, setSteps, steps]);

  const visibleSteps = useMemo(() => steps.filter((step) => step.visible), [steps]);
  const shouldRenderProgressBar = useMemo(() => visibleSteps.length > 1, [visibleSteps]);

  const nextButtonContent = () => {
    if (currentStep < visibleSteps.length - 1) {
      return (
        <>
          Next
          <Icon.ArrowRightLine size={5} className="mr-2" />
        </>
      );
    }

    return <>Save</>;
  };

  const backButtonContent = () => {
    if (currentStep > 0) {
      return (
        <>
          <Icon.ArrowLeftLine size={5} className="mr-2" />
          Back
        </>
      );
    }

    return <>Cancel</>;
  };

  const closeModal = useCallback(async () => {
    setCurrentStep(0);
    setNextDisabled(true);
    setStepCallback(() => defaultStepCallback);
    close();
  }, [close, setCurrentStep, setNextDisabled, setStepCallback]);

  const next = useCallback(async () => {
    if (!(await stepCallback())) return;

    await queryClient.invalidateQueries({
      queryKey: queryKeys,
    });

    if (currentStep < visibleSteps.length - 1) {
      setCurrentStep(currentStep + 1);
      setNextDisabled(true);
      setStepCallback(() => defaultStepCallback);
    } else {
      void (async () => await closeModal())();
    }
  }, [
    queryClient,
    stepCallback,
    currentStep,
    visibleSteps,
    setCurrentStep,
    setNextDisabled,
    setStepCallback,
    closeModal,
  ]);

  const previous = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setNextDisabled(false);
      setStepCallback(() => defaultStepCallback);
    } else {
      void closeModal();
    }
  }, [currentStep, setCurrentStep, setNextDisabled, setStepCallback, closeModal]);

  const renderCurrentStep = useCallback(() => {
    if (visibleSteps.length === 0 || visibleSteps[currentStep] == null) return null;

    return visibleSteps[currentStep].content();
  }, [visibleSteps, currentStep]);

  const ModalHeader: FC = () => {
    const progressHeader = (
      <span>
        Question <span className="font-semibold">{currentStep + 1}</span> of {visibleSteps.length}
      </span>
    );
    const singleQuestionHeader = <span>Edit single question</span>;

    return (
      <div className="pt-8 px-8">
        <div className="flex justify-between items-center pb-2">
          <div className="text-gray-500 text-sm">{shouldRenderProgressBar ? progressHeader : singleQuestionHeader}</div>

          <button
            onClick={closeModal}
            className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
            aria-label="Close"
          >
            <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
          </button>
        </div>

        {shouldRenderProgressBar && <ProgressBar now={currentStep} max={visibleSteps.length} />}
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="flex flex-col w-full gap-y-8 bg-gray-50">
        <ModalHeader />
        <div className="flex px-8 pb-16 flex-grow">{renderCurrentStep()}</div>
      </div>

      <div className="bg-white flex gap-x-8 justify-center px-8 py-6">
        <Button variant="secondary" onClick={previous}>
          {' '}
          {backButtonContent()}
        </Button>

        <Button variant="primary" onClick={next} disabled={nextDisabled}>
          {nextButtonContent()}
        </Button>
      </div>
    </Modal>
  );
};
