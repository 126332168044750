import * as apiGetTalentPoolData from '#root/src/api/endpoints/employers/talentPool/getTalentPoolData';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employers',
  talentPool = 'talentPool',
  data = 'data',
}

type GetApiPayload = apiGetTalentPoolData.Type['success']['payload'];
export const useGetTalentPoolData = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.employers, KeysEnum.talentPool, KeysEnum.data],
    queryFn: async () => await apiGetTalentPoolData.request(),
    ...options,
  });
