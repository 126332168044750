import { useQueryClient } from '@tanstack/react-query';
import { Opportunity } from '#src/types';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import * as editTeamMembersAPI from '#api/endpoints/employers/opportunities/editTeamMembers';
import { KeysEnum as opportunityQueryKeys } from '../../data/EmployerOpportunityData';

export interface FormValues extends FieldValues {
  teamMember1Name: string;
  teamMember1Position: string;
  teamMember1BackgroundIds: string[];

  teamMember2Name: string;
  teamMember2Position: string;
  teamMember2BackgroundIds: string[];

  teamMember3Name: string;
  teamMember3Position: string;
  teamMember3BackgroundIds: string[];
}

const defaultFormValues = (opportunity: Opportunity): FormValues => ({
  teamMember1Name: opportunity.teamMembersForm.teamMember1Name,
  teamMember1Position: opportunity.teamMembersForm.teamMember1Position,
  teamMember1BackgroundIds: opportunity.teamMembersForm.teamMember1BackgroundIds,
  teamMember2Name: opportunity.teamMembersForm.teamMember2Name,
  teamMember2Position: opportunity.teamMembersForm.teamMember2Position,
  teamMember2BackgroundIds: opportunity.teamMembersForm.teamMember2BackgroundIds,
  teamMember3Name: opportunity.teamMembersForm.teamMember3Name,
  teamMember3Position: opportunity.teamMembersForm.teamMember3Position,
  teamMember3BackgroundIds: opportunity.teamMembersForm.teamMember3BackgroundIds,
});

interface ControllerProps {
  opportunity: Opportunity;
  closeModal: () => void;
}

export const useOpportunityTeamMembersController = ({ opportunity, closeModal }: ControllerProps) => {
  const queryClient = useQueryClient();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: defaultFormValues(opportunity),
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await editTeamMembersAPI.request({
        id: opportunity.id,
        teamMembers: values,
      });
      closeModal();
      createSuccessToast({ title: 'Opportunity section' });
      void queryClient.invalidateQueries({
        queryKey: [
          opportunityQueryKeys.employers,
          opportunityQueryKeys.opportunities,
          opportunityQueryKeys.show,
          opportunity.id,
        ],
      });
    } catch (error) {
      createErrorToast({ title: 'Opportunity section' });
    }
  };

  return { form: formMethods, submitForm };
};
