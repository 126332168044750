import React, { FC, useState, useEffect, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { OpportunityThumbnail } from '#root/src/types';
import { request as apiExpressInterest } from '#api/endpoints/talents/opportunities/replies/interested';
import { KeysEnum as jobsQueryKeys } from '#pages/talentPages/activity/data/JobsData';
import QuestionsModal from './QuestionsModal';
import NotInterestedModal from './NotInterestedModal';
import InterestedModal from './InterestedModal';
import RequirementsModal from './RequirementsModal';

export type OpportunityReplyModalType = 'questions' | 'interested' | 'notInterested' | 'requirements' | null;

interface Props {
  opportunity: OpportunityThumbnail | null;
  selectedModal: OpportunityReplyModalType;
  setSelectedModal: (modal: OpportunityReplyModalType) => void;
  refetch: () => void;
}

const OpportunityRepliesModalManager: FC<Props> = ({ opportunity, selectedModal, setSelectedModal, refetch }) => {
  const queryClient = useQueryClient();

  const [questionsModalOpen, setQuestionsModalOpen] = useState(false);
  const [notInterestedModalOpen, setNotInterestedModalOpen] = useState(false);
  const [interestedModalOpen, setInterestedModalOpen] = useState(false);
  const [requirementsModalOpen, setRequirementsModalOpen] = useState(false);

  // To prevent stale data, we invalidate the queries that are related to the jobs as they might have changed
  const invalidateJobsQueries = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [jobsQueryKeys.talents, jobsQueryKeys.activity, jobsQueryKeys.jobs],
    });
  }, [queryClient]);

  const expressInterest = useCallback(() => {
    if (opportunity == null || opportunity.candidacy.state.type === 'interested') return;

    void (async () => {
      await apiExpressInterest({ opportunityId: opportunity.id });
      await invalidateJobsQueries();
      refetch();
    })();
  }, [opportunity, refetch, invalidateJobsQueries]);

  useEffect(() => {
    switch (selectedModal) {
      case 'questions':
        setQuestionsModalOpen(true);
        break;
      case 'interested':
        setRequirementsModalOpen(false);
        setInterestedModalOpen(true);
        expressInterest();
        break;
      case 'notInterested':
        setNotInterestedModalOpen(true);
        break;
      case 'requirements':
        setRequirementsModalOpen(true);
        break;
      default:
        setQuestionsModalOpen(false);
        setInterestedModalOpen(false);
        setNotInterestedModalOpen(false);
        setRequirementsModalOpen(false);
    }
  }, [opportunity, selectedModal, expressInterest]);

  if (opportunity == null) return null;

  return (
    <div>
      <QuestionsModal
        isOpen={questionsModalOpen}
        onClose={() => setSelectedModal(null)}
        opportunityId={opportunity.id}
        refetch={refetch}
        invalidateJobsQueries={invalidateJobsQueries}
      />
      <InterestedModal
        isOpen={interestedModalOpen}
        onClose={() => setSelectedModal(null)}
        opportunityId={opportunity.id}
        refetch={refetch}
        invalidateJobsQueries={invalidateJobsQueries}
      />
      <NotInterestedModal
        isOpen={notInterestedModalOpen}
        onClose={() => setSelectedModal(null)}
        refetch={refetch}
        opportunityId={opportunity.id}
      />
      <RequirementsModal
        requirementsTitle={opportunity?.requirementsTitle}
        requirementsBody={opportunity?.requirementsBody}
        requirements={opportunity?.requirements}
        confirmAction={() => setSelectedModal('interested')}
        isOpen={requirementsModalOpen}
        onClose={() => setSelectedModal(null)}
      />
    </div>
  );
};

export default OpportunityRepliesModalManager;
