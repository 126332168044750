import { ReactQueryOptions } from '#src/types';
import { useQuery } from '@tanstack/react-query';

import * as apiGetHandpickedOpportunities from '#api/endpoints/talents/opportunities/handpickedOpportunities';

export enum KeysEnum {
  talents = 'talents',
  opportunities = 'opportunities',
  handpickedOpportunities = 'handpicked-opportunities',
}

type GetApiPayload = apiGetHandpickedOpportunities.Type['success']['payload'];

export const useGetHandpickedOpportunities = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.talents, KeysEnum.opportunities, KeysEnum.handpickedOpportunities],
    queryFn: async () => await apiGetHandpickedOpportunities.request(),
    ...options,
  });
