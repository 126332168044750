import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: { slug: string };
  success: {
    successStory: {
      metaTitle: string;
      aboutCompany?: string;
      aboutRole?: string;
      company: string;
      companyImageUrl: string;
      id: number;
      location: string;
      member?: {
        image?: string;
        fullName?: string;
        position?: string;
        quote?: string;
      };
      otherSuccessStories: Array<{
        id: number;
        slug: string;
        company: string;
        companyImageUrl: string;
        position: string;
        location: string;
        candidatesBackgrounds: Array<{ imageUrl: string; alt: string }>;
      }>;
      position: string;
      requirements?: string;
      statistics: Array<{ title: string | ''; value: number | null }>;
      story: string;
    };
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, `/public/success_stories/${params.slug}`)(params);
