import * as apiEditEmployerTeamMember from '#api/endpoints/session/editTeamMember';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  signUp = 'sign-up',
  employer = 'employer',
  teamMember = 'team-member',
  edit = 'edit',
}

type GetApiPayload = apiEditEmployerTeamMember.Type['success']['payload'];
export const useEditEmployerTeamMemberData = (token: string, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.signUp, KeysEnum.employer, KeysEnum.teamMember, KeysEnum.edit],
    queryFn: async () => await apiEditEmployerTeamMember.request({ token }),
    ...options,
  });
