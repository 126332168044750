import * as apiGetCoachFilterOptions from '#api/endpoints/coaches/getCoachFilterOptions';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  homepage = 'homepage',
  interviewPrep = 'interview-prep',
  coaches = 'coaches',
  filters = 'filters',
}

type GetApiPayload = apiGetCoachFilterOptions.Type['success']['payload'];
export const useGetCoachFilterOptions = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.homepage, KeysEnum.interviewPrep, KeysEnum.coaches, KeysEnum.filters],
    queryFn: async () => await apiGetCoachFilterOptions.request(),
    ...options,
  });
