import React, { ReactNode, FC, useEffect, useRef, useState } from 'react';
import styles from './Carousel.module.css';
import { Button, Icon } from '../Index';

interface Props {
  components: ReactNode[];
  className?: string;
  slideClassName?: string;
}

const Carousel: FC<Props> = ({ components, className = '', slideClassName = '' }) => {
  const [active, setActive] = useState(0);
  const itemContainerRef = useRef<HTMLDivElement>(null);

  if (components.length <= 0) {
    throw Error('You must provide components to carousel');
  }

  const scrollIntoView = (index: number) => {
    const container = itemContainerRef.current;
    if (container == null) return;

    const itemWidth = container.clientWidth;
    const scrollTo = itemWidth * index;

    container.scrollTo({
      left: scrollTo,
      behavior: 'smooth',
    });
  };

  const handleOnPrevious = () => {
    const newActive = active > 0 ? active - 1 : components.length - 1;
    scrollIntoView(newActive);
  };

  const handleOnNext = () => {
    const newActive = active < components.length - 1 ? active + 1 : 0;
    scrollIntoView(newActive);
  };

  const handleIndicatorClick = (index: number) => {
    scrollIntoView(index);
  };

  useEffect(() => {
    const itemActiveObservers = components.map((_, index) => {
      const observer = new IntersectionObserver((entries) => entries[0].isIntersecting && setActive(index), {
        threshold: 0.5,
      });

      const item = itemContainerRef.current?.children[index];

      if (item != null) {
        observer.observe(item);
      }

      return observer;
    });

    return () => {
      itemActiveObservers.forEach((observer) => observer.disconnect);
    };
  }, [itemContainerRef, components]);

  return (
    <div className={className}>
      <div className="flex gap-4 md:gap-8 max-w-6xl mx-auto">
        <Button
          variant="secondary"
          circle
          onClick={handleOnPrevious}
          className="self-center max-md:[@media(hover:none)]:!hidden"
          title="Previous slide"
        >
          <Icon.ArrowLeftLine size={5} aria-hidden="true" />
        </Button>

        <div
          className="basis-full w-screen flex overflow-auto snap-mandatory snap-x scrollbar-hide focus-visible:outline focus-visible:outline-blue-300"
          ref={itemContainerRef}
        >
          {components.map((component, index) => (
            <div key={index} className={`w-full shrink-0 snap-always snap-center ${slideClassName}`}>
              {component}
            </div>
          ))}
        </div>

        <Button
          variant="secondary"
          circle
          onClick={handleOnNext}
          className="self-center max-md:[@media(hover:none)]:!hidden"
          title="Next slide"
        >
          <Icon.ArrowRightLine size={5} aria-hidden="true" />
        </Button>
      </div>

      <div className="flex justify-center gap-2 mt-14">
        {components.map((_, index) => (
          <button
            key={index}
            onClick={() => handleIndicatorClick(index)}
            className={styles.indicator}
            aria-label={`Carousel indicator button ${index + 1}`}
            aria-pressed={index === active}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
