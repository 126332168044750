import React, { FC, useEffect, useState } from 'react';

import { useStepController } from './StepControllerContext';
import { Button, Icon, ProgressBar, ProgressCircleGraphic } from '#root/src/components/Index';
import * as apiCancelOnboarding from '#api/endpoints/onboarding/cancelOnboarding';
import { Spinner } from '#components/Index';

export interface Step {
  id: string;
  displayName?: string;
  onDarkBackground?: boolean;
  Body: FC<{
    setNextDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
    setHandleNextClick?: React.Dispatch<React.SetStateAction<() => void>>;
    setCanCancelOnboarding?: React.Dispatch<React.SetStateAction<boolean>>;
    setNextButtonText?: React.Dispatch<React.SetStateAction<string>>;
  }>;
  Footer: FC<{
    nextDisabled?: boolean;
    onNextClick?: () => void;
    canCancelOnboarding?: boolean;
    nextButtonText?: string;
  }>;
}

export const CurrentStep: FC = () => {
  const { getCurrentStep, progress, stepLoaded } = useStepController();
  const CurrentStep = getCurrentStep();
  const [nextDisabled, setNextDisabled] = useState(false);
  const [handleNextClick, setHandleNextClick] = useState<() => void>(() => {});
  const [canCancelOnboarding, setCanCancelOnboarding] = useState<boolean>(false);
  const [nextButtonText, setNextButtonText] = useState<string>('Next');

  if (!stepLoaded) return <Spinner className="min-h-full" />;

  return (
    <div
      className={`relative min-h-full-content flex flex-col ${
        CurrentStep.onDarkBackground != null ? 'bg-hpblack' : 'bg-gray-50'
      }`}
    >
      <StepBodyWrapper>
        <CurrentStep.Body
          setNextDisabled={setNextDisabled}
          setHandleNextClick={setHandleNextClick}
          setCanCancelOnboarding={setCanCancelOnboarding}
          setNextButtonText={setNextButtonText}
        />
      </StepBodyWrapper>
      <StepFooterWrapper>
        <CurrentStep.Footer
          nextDisabled={nextDisabled}
          onNextClick={handleNextClick}
          canCancelOnboarding={canCancelOnboarding}
          nextButtonText={nextButtonText}
        />
      </StepFooterWrapper>
      <div className="fixed bottom-0 right-0 max-lg:hidden">
        <ProgressCircleGraphic
          now={progress.now}
          max={progress.max}
          onDarkBackground={CurrentStep.onDarkBackground}
          width="236"
          height="236"
        />
      </div>
    </div>
  );
};

interface StepBodyWrapperProps {
  children: React.ReactNode;
}

export const StepBodyWrapper: FC<StepBodyWrapperProps> = ({ children }) => {
  const { getCurrentStep, progress } = useStepController();
  const { onDarkBackground } = getCurrentStep();

  return (
    <div className="grow flex flex-col px-variable-lg py-12 lg:px-[max(15vw-3rem,236px)]">
      <div className="flex flex-col mx-auto max-w-screen-xl grow w-full">
        <ProgressBar now={progress.now} max={progress.max} onDarkBackground={onDarkBackground} />
        <div className="grow flex flex-col mt-10">{children}</div>
      </div>
    </div>
  );
};

interface StepFooterWrapperProps {
  children: React.ReactNode;
}

export const StepFooterWrapper: FC<StepFooterWrapperProps> = ({ children }) => {
  const { getCurrentStep } = useStepController();
  const { onDarkBackground } = getCurrentStep();
  const [hasOverflow, setHasOverflow] = useState(false);

  const handleMaybeOverflowing = () => {
    setHasOverflow(document.documentElement.getBoundingClientRect().bottom > document.documentElement.clientHeight + 1);
  };

  useEffect(() => {
    handleMaybeOverflowing();
    window.addEventListener('scroll', handleMaybeOverflowing);
    window.addEventListener('resize', handleMaybeOverflowing);

    return () => {
      window.removeEventListener('scroll', handleMaybeOverflowing);
      window.removeEventListener('resize', handleMaybeOverflowing);
    };
  }, []);

  return (
    <div
      className={`flex justify-center inset-x-0 bottom-0 p-8 bg-opacity-90 pointer-events-none ${
        onDarkBackground != null ? 'bg-hpblack' : 'bg-gray-50'
      }`}
    >
      <div
        className={`absolute inset-0 max-w-6xl mx-auto shadow-[0_0_18px_rgba(0_0_0/.05),0_0_6px_rgba(0_0_0/.05)] lg:rounded-t-full [clip-path:polygon(0%_-50%,100%_-50%,100%_0%,0_0)] transition ${
          hasOverflow ? '' : 'duration-700 opacity-0'
        }`}
      />
      <div className="flex gap-6 pointer-events-auto">{children}</div>
    </div>
  );
};

interface TitleSlideBodyProps {
  surtitle?: string;
  title?: string;
}

export const TitleSlideBody: FC<TitleSlideBodyProps> = ({ surtitle, title }) => (
  <div className="my-auto text-center">
    <span className="uppercase text-green-500 text-xs font-bold tracking-widest">{surtitle}</span>
    <h1 className="text-white font-serif text-display-lg 2xs:text-display-xl sm:text-display-2xl mt-4">{title}</h1>
  </div>
);

export const StepBackButton: FC<{ disabled?: boolean; cancelOnboarding?: boolean }> = ({
  disabled,
  cancelOnboarding,
}) => {
  const { previous } = useStepController();

  const handleClick = async () => {
    if (cancelOnboarding === true) {
      const result = await apiCancelOnboarding.request();
      window.location.href = result.redirectUrl;

      return;
    }

    previous();
  };

  const buttonText = cancelOnboarding === true ? 'Cancel Onboarding' : 'Back';

  return (
    <Button size="large" variant="secondary" onClick={handleClick} disabled={disabled} className="max-2xs:!px-8">
      <Icon.ArrowLeftLine size={5} aria-hidden="true" />
      {buttonText}
    </Button>
  );
};

export const StepNextButton: FC<{ disabled?: boolean; onClick?: () => void; text?: string }> = ({
  disabled,
  onClick,
  text = 'Next',
}) => {
  const { next } = useStepController();

  const handleClick = onClick ?? next;

  return (
    <Button size="large" variant="primary" onClick={handleClick} disabled={disabled} className="max-2xs:!px-8">
      {text}
      <Icon.ArrowRightLine size={5} aria-hidden="true" />
    </Button>
  );
};
