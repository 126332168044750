import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { BuildCareer } from '#components/Index';
import CoreValues from './elements/CoreValues';
import Founders from './elements/Founders';
import Header from './elements/Header';
import JoinTeam from './elements/JoinTeam';
import Team from './elements/Team';

const About: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Hiperpool - About us</title>
        <meta name="description" content="Discover the story behind Hiperpool from our ex-Bain and ex-Booz Founders." />
      </Helmet>
      <Header />
      <Founders />
      <CoreValues />
      <Team />
      <JoinTeam />
      <BuildCareer />
    </div>
  );
};

export default About;
