import * as apiGetListOfCoaches from '#api/endpoints/coaches/getList';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  homepage = 'homepage',
  interviewPrep = 'interview-prep',
  coaches = 'coaches',
}

type GetApiPayload = apiGetListOfCoaches.Type['success']['payload'];
export const useGetCoaches = (
  region: string,
  privacyCompany: string,
  coachingType: string,
  options?: ReactQueryOptions<GetApiPayload>
) =>
  useQuery({
    queryKey: [KeysEnum.homepage, KeysEnum.interviewPrep, KeysEnum.coaches, region, privacyCompany, coachingType],
    queryFn: async () => await apiGetListOfCoaches.request({ region, privacyCompany, coachingType }),
    ...options,
  });
