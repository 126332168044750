import { string, object } from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import * as editOpportunityContentSectionAPI from '#api/endpoints/employers/opportunities/editContentSection';
import { KeysEnum as opportunityQueryKeys } from '../../data/EmployerOpportunityData';

export interface FormValues extends FieldValues {
  content: string;
}

const defaultFormValues = (content: string): FormValues => ({
  content,
});

const validationSchema = object().shape({
  content: string().required('Content is required'),
});

interface ControllerProps {
  id: number;
  initialContent: string;
  section: editOpportunityContentSectionAPI.Type['init']['payload']['opportunity']['section'];
  closeModal: () => void;
}

export const useOpportunityEditSectionController = ({ id, section, initialContent, closeModal }: ControllerProps) => {
  const queryClient = useQueryClient();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(initialContent),
  });

  const { parseErrors } = useParseFormErrors<editOpportunityContentSectionAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await editOpportunityContentSectionAPI.request({
        id,
        opportunity: {
          section,
          content: values.content,
        },
      });
      closeModal();
      createSuccessToast({ title: 'Opportunity section' });
      void queryClient.invalidateQueries({
        queryKey: [opportunityQueryKeys.employers, opportunityQueryKeys.opportunities, opportunityQueryKeys.show, id],
      });
    } catch (errors) {
      createErrorToast({ title: 'Opportunity section' });
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm };
};
