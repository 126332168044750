import { ReactQueryOptions } from '#src/types';
import { useQuery } from '@tanstack/react-query';

import * as apitGetOpenPositions from '#api/endpoints/public/openPositions';

export enum KeysEnum {
  contactUs = 'contact-us',
  joinOurTeam = 'join-our-team',
}

type GetApiPayload = apitGetOpenPositions.Type['success']['payload'];
export const useGetOpenPositions = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.contactUs, KeysEnum.joinOurTeam],
    queryFn: async () => await apitGetOpenPositions.request(),
    ...options,
  });
