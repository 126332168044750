import React, { FC, useMemo } from 'react';
import BlockQuote from '#components/BlockQuote/BlockQuote';
import Carousel from '#components/Carousel/Carousel';
import MargaretRJPEG from '#assets/people/margaretR.jpeg';
import JyotishmanSJPEG from '#assets/people/jyotishmanS.jpeg';
import MadisonOJPEG from '#assets/people/madisonO.jpeg';
import SimoneFJPEG from '#assets/people/simoneF.jpeg';
import JulianCJPG from '#assets/people/julianC.jpg';
import ConnorNJPG from '#assets/people/connorN.jpg';
import CharlesMJPG from '#assets/people/charlesM.jpg';
import { CircleGraphic } from '#components/Index';

const TestimonialCarousel: FC = () => {
  const people = useMemo(
    () => [
      {
        src: JulianCJPG,
        name: 'Julien C',
        jobDescription: 'Finance Director at Beckers Group',
        quote:
          "I had the pleasure of working with Hiperpool during my recent job search, and I couldn't be more grateful for their expertise and support. They provided valuable insights and feedback that helped me prepare for interviews and present my best self to my future employer. I highly recommend Hiperpool to anyone looking for a job or considering a career change.",
      },
      {
        src: ConnorNJPG,
        name: 'Connor N',
        jobDescription: 'Global Business Improvement Manager, TUV Rheinland',
        quote:
          'Hiperpool provided strong valuable support throughout my job search. Their structured approach and tailored opportunities helped me secure a great role that aligns perfectly with my skills and career ambitions.',
      },
      {
        src: CharlesMJPG,
        name: 'Charles M',
        jobDescription: 'Finance Director, Beckers Group',
        quote:
          'Ben at Hiperpool was incredibly helpful in guiding me through the process for a transformation-focused FD role. Detailed insights into the company’s strategy and ambitions, combined with explaining cultural differences across the EMEA region was provided. This gave me much better transparency about the role, and ensured I could ask the right questions during the process to be confident the position was a good match for my skillset.',
      },
      {
        src: MadisonOJPEG,
        name: 'Madison O',
        jobDescription: 'Partnerships at BibliU',
        quote:
          'Hiperpool uncovers a variety of exciting roles across a wide range of industries, from up-and-coming startups to well-established brands',
      },
      {
        src: SimoneFJPEG,
        name: 'Simone F',
        jobDescription: 'Category Manager at Manual.com',
        quote:
          'Hiperpool was incredibly effective in my career move. The team supported me along the whole process of joining my next role!',
      },
      {
        src: MargaretRJPEG,
        name: 'Margaret R',
        jobDescription: 'Customer Impact Manager at Clarify Health Solutions',
        quote:
          'Hiperpool helped me find the best next career move where I can leverage my consulting toolkit and industry knowledge in a fast-paced and growing technology company.',
      },
      {
        src: JyotishmanSJPEG,
        name: 'Jyotishman S',
        jobDescription: 'Strategy & Ops at Google',
        quote:
          'Hiperpool has been absolutely fantastic to work with. I received tailored opportunities and landed my new role in the function and industry I wanted!',
      },
    ],
    []
  );
  return (
    <Carousel
      className="pt-16 pb-14 px-variable-sm max-md:[@media(hover:none)]:px-0 bg-gray-50 max-w-screen"
      slideClassName="max-md:[@media(hover:none)]:px-variable-md place-self-center"
      components={people.map((elm) => (
        <BlockQuote
          key={elm.name}
          className="mx-auto max-w-4xl"
          image={
            <div className="grid grid-cols-8 grid-rows-9 aspect-[16/9]">
              <img
                src={elm.src}
                height="200"
                width="200"
                className="row-start-1 row-span-8 col-start-5 col-span-4 w-full"
                loading="lazy"
                alt=""
              />
              <CircleGraphic className="row-start-2 row-span-8 col-start-1 col-span-5 w-full h-auto">
                <CircleGraphic.Half x={4} y={1} color="green-900" rotate={90} />
                <CircleGraphic.Quarter x={2} y={2} color="hpblack" rotate={90} />
                <CircleGraphic.Quarter x={3} y={2} color="green-900" rotate={180} />
                <CircleGraphic.Half x={2} y={3} color="green-900" rotate={180} />
                <CircleGraphic.Quarter x={3} y={3} color="green-500" rotate={-90} />
                <CircleGraphic.Quarter x={4} y={3} color="hpblack" rotate={0} />
                <CircleGraphic.Circle x={2} y={4} color="green-500" radius={0.5} />
                <CircleGraphic.Quarter x={3} y={4} color="green-900" rotate={180} />
                <CircleGraphic.Quarter x={4} y={4} color="green-900" rotate={90} />
                <CircleGraphic.Circle x={5} y={4} color="green-500" radius={0.5} />
              </CircleGraphic>
            </div>
          }
          jobDescription={elm.jobDescription}
          name={elm.name}
        >
          <p>{elm.quote}</p>
        </BlockQuote>
      ))}
    />
  );
};

export default TestimonialCarousel;
