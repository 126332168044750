import React, { FC, useCallback, useState, useMemo } from 'react';
import _ from 'lodash';
import { OpportunitySearchParams } from '#src/types';
import { Checkbox, MultiStringInput } from '#root/src/components/Form/Index';
import { Icon, Button } from '#root/src/components/Index';
import OpportunityFilterGroup from './OpportunityFilterGroup';

interface OpportunityFilterProps {
  search: OpportunitySearchParams;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
  regions: string[];
  firms: string[];
  workPolicies: string[];
  preselectedFilters?: OpportunitySearchParams['filters'];
}

const OpportunityFilters: FC<OpportunityFilterProps> = ({ search, setSearch, regions, firms, workPolicies }) => {
  const [showFilters, setShowFilters] = useState(false);

  const filters = search.filters;

  const filterAndKeywordsCounter = useMemo(() => {
    return (
      search.keywords.length +
      (search.filters?.firms ?? []).length +
      (search.filters?.regions ?? []).length +
      (search.filters?.workPolicies ?? []).length +
      (search.filters?.contractTypes ?? []).length +
      (search.filters.showArchived === true ? 1 : 0)
    );
  }, [search]);

  const humanizeRegionName = (regionName: string) => {
    if (['UK', 'US', 'APAC'].includes(regionName)) {
      return regionName;
    }

    if (regionName === 'REST_OF_WORLD') {
      return 'Rest of the World';
    }

    return _.startCase(regionName.toLowerCase().replace(/_/g, ' '));
  };

  const handleFilterChange = (filterKey: 'firms' | 'workPolicies' | 'regions' | 'contractTypes', value: string) => {
    const updatedFilters = [...(filters[filterKey] ?? [])];
    const valueIndex = updatedFilters.indexOf(value);

    if (valueIndex > -1) {
      // If the value is found, remove it from the array (uncheck)
      updatedFilters.splice(valueIndex, 1);
    } else {
      // If the value is not found, add it to the array (check)
      updatedFilters.push(value);
    }

    const newSearchValue = {
      ...search,
      filters: {
        ...filters,
        [filterKey]: updatedFilters,
      },
    };

    setSearch(newSearchValue);
  };

  const handleKeywordChange = (keywords: string[]) => {
    const newSearchValue = { ...search, keywords };

    setSearch(newSearchValue);
  };

  const setArchivedFilter = (selected: boolean) => {
    const newSearchValue = { ...search, filters: { ...filters, showArchived: selected } };

    setSearch(newSearchValue);
  };

  const searchFilterSelected = useCallback(
    (filterKey: 'firms' | 'workPolicies' | 'regions' | 'contractTypes', value: string): boolean => {
      if (filters[filterKey] == null) return false;

      return filters[filterKey]?.includes(value) ?? false;
    },
    [filters]
  );

  return (
    <div>
      <div>
        <MultiStringInput className="w-full" placeholder="Search keywords..." onChange={handleKeywordChange} />
        <div className="block lg:hidden">
          <Button
            variant="link"
            size="small"
            className="flex !bg-gray-100 !bg-opacity-100 mt-1 mb-4 ml-2"
            onClick={() => setShowFilters(!showFilters)}
          >
            <Icon.FilterFill size={6} />
            Filters
            <span className="flex text-xs items-center justify-center text-green-900 bg-green-200 min-w-[1.25rem] w-auto h-5 rounded-sm px-1">
              {filterAndKeywordsCounter}
            </span>
          </Button>
        </div>
      </div>

      {/* On large screens filters always should be visible */}
      <div className={showFilters ? '' : 'hidden lg:block'}>
        <div className="px-4 xl:px-0">
          <OpportunityFilterGroup
            title="Firms"
            items={firms}
            filterKey="firms"
            searchFilterSelected={searchFilterSelected}
            handleFilterChange={handleFilterChange}
          />

          <OpportunityFilterGroup
            title="Location"
            items={regions}
            filterKey="regions"
            searchFilterSelected={searchFilterSelected}
            handleFilterChange={handleFilterChange}
            parsedOption={humanizeRegionName}
          />

          <OpportunityFilterGroup
            title="Work Policies"
            items={workPolicies}
            filterKey="workPolicies"
            searchFilterSelected={searchFilterSelected}
            handleFilterChange={handleFilterChange}
          />

          {/* <OpportunityFilterGroup
            title="Contract Types"
            items={contractTypes}
            filterKey="contractTypes"
            searchFilterSelected={searchFilterSelected}
            handleFilterChange={handleFilterChange}
          /> */}

          <div className="b-gray-300 border-t-2 pt-3 my-4">
            <div className="flex flex-col text-sm">
              <Checkbox
                checked={filters.showArchived ?? false}
                onChange={(event) => setArchivedFilter(event.currentTarget.checked)}
              >
                Show &apos;Archived&apos;
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityFilters;
