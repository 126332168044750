import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'react-hot-toast';

import './index.css';
import { AppRoutes } from './AppRoutes';
import CookieConsent from './components/CookieConsent/CookieConsent';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <HelmetProvider>
    <Helmet>
      <title>Hiperpool</title>
      <meta
        name="description"
        content="Hiperpool is a specialist global recruitment firm focused on strategy, consulting, and finance professionals."
      />
    </Helmet>
    <React.StrictMode>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 2500,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
        <ReactQueryDevtools initialIsOpen={false} />
        <CookieConsent />
      </QueryClientProvider>
    </React.StrictMode>
  </HelmetProvider>
);
