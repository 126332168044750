import { ReactQueryOptions } from '#src/types';
import { useQuery } from '@tanstack/react-query';

import * as apiGetAccountSettings from '#api/endpoints/talents/settings/getAccountSettings';

export enum KeysEnum {
  talents = 'talents',
  settings = 'settings',
}

type GetApiPayload = apiGetAccountSettings.Type['success']['payload'];

export const useGetAccountSettings = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.talents, KeysEnum.settings],
    queryFn: async () => await apiGetAccountSettings.request(),
    ...options,
  });
