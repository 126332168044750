import React, { FC, useRef, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { EmployerInterviewingCandidate } from '#src/types';
import { request as apiMarkAsHired } from '#api/endpoints/employers/offers/markAsHired';
import { KeysEnum as candidatesQueryKeys } from '../data/InterviewingCandidatesData';
import { LockedMessaging } from '#components/Index';
import CandidateHistoryItem from './candidate/CandidateHistoryItem';
import OfferReply from './candidate/OfferReply';
import CandidateHeader from './candidate/CandidateHeader';

interface CandidateContentProps {
  selectedCandidate: EmployerInterviewingCandidate | null;
  setCandidatesListOpenOnMobile?: Dispatch<SetStateAction<boolean>>;
}

const CandidateContent: FC<CandidateContentProps> = ({
  selectedCandidate,
  setCandidatesListOpenOnMobile = () => {},
}) => {
  const queryClient = useQueryClient();
  const historyRef = useRef<HTMLDivElement>(null);

  // Scroll to the bottom of the history when the selected job changes
  useEffect(() => {
    if (historyRef.current != null) {
      historyRef.current.scrollTop = historyRef.current.scrollHeight;
    }
  }, [selectedCandidate]);

  const markAsHired = useCallback(async () => {
    if (selectedCandidate == null) return;

    await apiMarkAsHired({ id: selectedCandidate.offer.id });
    await queryClient.invalidateQueries({
      queryKey: [candidatesQueryKeys.employers, candidatesQueryKeys.talentPool, candidatesQueryKeys.candidates],
    });
  }, [selectedCandidate, queryClient]);

  if (selectedCandidate == null) return null;

  return (
    <>
      <CandidateHeader
        selectedCandidate={selectedCandidate}
        setCandidatesListOpenOnMobile={setCandidatesListOpenOnMobile}
      />

      <div className="flex flex-col grow">
        <div className="flex flex-col grow justify-between">
          <div ref={historyRef} className="overflow-y-auto lg:max-h-[calc(50dvh)] 2xl:max-h-[calc(70dvh)] p-8">
            <ul role="list">
              {selectedCandidate.history.entries.map((historyItem, historyIndex) => (
                <CandidateHistoryItem
                  key={historyIndex}
                  {...historyItem}
                  isLast={selectedCandidate.history.entries.length - 1 === historyIndex}
                  avatarUrl={selectedCandidate.profile.avatarUrl}
                />
              ))}
            </ul>
          </div>

          {!selectedCandidate.offer.archived && <OfferReply candidate={selectedCandidate} />}

          {selectedCandidate.offer.archived && !selectedCandidate.offer.hired && (
            <LockedMessaging
              message="Did you hire this candidate? Let us know so we can send our congrats!"
              buttonText="Mark as hired!"
              handleButtonClick={markAsHired}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CandidateContent;
