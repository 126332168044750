import { useQuery } from '@tanstack/react-query';

import * as apiGetFeaturedStories from '#api/endpoints/stories/getFeaturedList';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  homepage = 'homepage',
  employer = 'employer',
  recordOfSuccess = 'record-of-success',
}

type GetApiPayload = apiGetFeaturedStories.Type['success']['payload'];
export const useGetFeaturedStories = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.homepage, KeysEnum.employer, KeysEnum.recordOfSuccess],
    queryFn: async () => await apiGetFeaturedStories.request(),
    ...options,
  });
