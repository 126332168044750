import React, { FC, useState, useMemo } from 'react';
import useInfiniteScrollPagination from '#src/lib/useInfiniteScrollPagination';
import { paths } from '#pages/paths';
import { useGetStories } from './StoriesData';
import { Button, SuccessCard, Spinner } from '#components/Index';
import FilterBadgeGroup from '#components/FilterBadgeGroup/FilterBadgeGroup';

const initialTypeFilterState: Record<string, boolean> = {
  'Startup': false,
  'Corporate': false,
  'Consulting': false,
  'PE/PE-backed': false,
};

const initialLocationFilterState: Record<string, boolean> = {
  USA: false,
  EMEA: false,
};

const Stories: FC = () => {
  const [typeFilter, setTypeFilter] = useState(initialTypeFilterState);
  const [locationFilter, setLocationFilter] = useState(initialLocationFilterState);
  const { data, isLoading } = useGetStories();

  const filteredList = useMemo(() => {
    const locationFilters = Object.entries(locationFilter).reduce<string[]>((prev, curr, arr) => {
      const [key, value] = curr;
      if (value) prev.push(key.toLowerCase());
      return prev;
    }, []);
    const companyFilter = Object.entries(typeFilter).reduce<string[]>((prev, curr, arr) => {
      const [key, value] = curr;
      if (value) prev.push(key.toLowerCase());
      return prev;
    }, []);

    return data?.successStories.filter(
      (story) =>
        (locationFilters.length === 0 ? true : locationFilters.includes(story.locationTag)) &&
        (companyFilter.length === 0 ? true : companyFilter.includes(story.companyType))
    );
  }, [data?.successStories, typeFilter, locationFilter]);

  const { list, isMore, loadMore } = useInfiniteScrollPagination({ list: filteredList, perPage: 6 });

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  return (
    <div className="grid justify-center py-16 px-variable-sm">
      <div className="grid place-items-center gap-10">
        <FilterBadgeGroup
          id="success-story-type-filter"
          label="Filter by type"
          filters={typeFilter}
          setFilter={setTypeFilter}
        />
        <FilterBadgeGroup
          id="success-story-location-filter"
          label="Filter by location"
          filters={locationFilter}
          setFilter={setLocationFilter}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 max-w-sm sm:max-w-3xl lg:max-w-6xl gap-x-8 gap-y-16 py-12 mx-auto">
        {list.length > 0 &&
          list.map((story, idx) => (
            <SuccessCard
              key={`${story.slug} - ${idx}`}
              link={paths.story({ slug: story.slug })}
              position={story.position}
              location={story.location}
              logo={
                <img
                  src={story.companyImageUrl}
                  alt={`${story.company} logo`}
                  loading="eager"
                  className="object-contain"
                />
              }
              backgrounds={story.candidatesBackgrounds}
            />
          ))}
      </div>
      {isMore && (
        <div className="flex justify-center mb-12">
          <Button variant="secondary" size="large" onClick={loadMore}>
            View more
          </Button>
        </div>
      )}
    </div>
  );
};

export default Stories;
