import React, { FC, useMemo, useState, useEffect } from 'react';
import uniq from 'lodash/uniq';
import { paths } from '#pages/paths';
import useInfiniteScrollPagination from '#src/lib/useInfiniteScrollPagination';
import { useGetPosts } from './PostsData';
import FilterBadgeGroup from '#components/FilterBadgeGroup/FilterBadgeGroup';
import { Button, ArticleCard, Spinner } from '#components/Index';

const initialCategoryFilterState: Record<string, boolean> = {
  'Company News': false,
  'For Employers': false,
  'Insights': false,
  'Recruitment Resources': false,
};

const Posts: FC = () => {
  const [categoryFilter, setCategoryFilter] = useState(initialCategoryFilterState);
  const { data, isLoading } = useGetPosts();

  useEffect(
    function updateInitialFiltersBasedOnActualData() {
      if (data != null) {
        const filterList = uniq(data?.posts.map((p) => p.mainCategory)).sort();
        const filterObj = Object.fromEntries(filterList.map((filter) => [filter, false]));
        setCategoryFilter(filterObj);
      }
    },
    [data]
  );

  const filteredList = useMemo(() => {
    const categoryFilterList = Object.entries(categoryFilter).reduce<string[]>((prev, curr) => {
      const [key, value] = curr;
      if (value) prev.push(key);
      return prev;
    }, []);

    if (categoryFilterList.length <= 0) {
      return data?.posts;
    }

    return data?.posts.filter((post) => categoryFilterList.includes(post.mainCategory));
  }, [data?.posts, categoryFilter]);

  const { list, isMore, loadMore } = useInfiniteScrollPagination({ list: filteredList, perPage: 6 });

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  return (
    <div className="grid gap-16 px-variable-sm py-16 justify-center">
      <div className="grid place-items-center gap-10">
        <FilterBadgeGroup
          id="blog-posts-category-filter"
          label="Filter by category"
          filters={categoryFilter}
          setFilter={setCategoryFilter}
        />
      </div>
      <div className="grid xs:grid-cols-2 lg:grid-cols-3 gap-x-7 lg:gap-x-8 gap-y-12 lg:gap-y-14 max-w-6xl">
        {list.length > 0 &&
          list.map((post, idx) => (
            <ArticleCard
              key={`${post.slug}_${idx}`}
              title={post.title}
              description={post.truncatedContent}
              image={post.imageUrl}
              category={post.mainCategory}
              link={paths.blogPost({ slug: post.slug })}
            />
          ))}
      </div>
      {isMore && (
        <div className="flex justify-center mb-12">
          <Button variant="secondary" size="large" onClick={loadMore}>
            View more
          </Button>
        </div>
      )}
    </div>
  );
};

export default Posts;
