import * as apiGetArchivedCandidates from '#api/endpoints/employers/talentPool/getArchivedCandidates';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employers',
  talentPool = 'talentPool',
  candidates = 'candidates',
  archived = 'archived',
}

type GetApiPayload = apiGetArchivedCandidates.Type['success']['payload'];
export const useGetArchivedCandidatesData = (opportunityId?: number, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.employers, KeysEnum.talentPool, KeysEnum.candidates, KeysEnum.archived, opportunityId].filter(
      (item) => item != null
    ),

    queryFn: async () => await apiGetArchivedCandidates.request({ opportunityId }),
    ...options,
  });
