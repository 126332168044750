import * as apiGetLanguageProficiencyLevels from '#api/endpoints/onboarding/languageProficiencyLevels';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  languageProficiencyLevels = 'language-proficiency-levels',
}

type GetApiPayload = apiGetLanguageProficiencyLevels.Type['success']['payload'];
export const useGetLanguageProficiencyLevels = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.languageProficiencyLevels],
    queryFn: async () => await apiGetLanguageProficiencyLevels.request(),
    ...options,
  });
