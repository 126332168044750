import React, { FC } from 'react';
import { SessionCardPending, SessionCardUpcoming } from '#components/Index';
import { Coach } from '#root/src/types';
import { paths } from '#root/src/pages/paths';

const UpcomingSessions: FC<{ coaches: Coach[] | undefined }> = ({ coaches }) => {
  if (coaches === undefined) {
    return null;
  }

  return (
    <div>
      {coaches.some((coach) => coach.pendingSessions.length > 0 || coach.upcomingSessions.length > 0) && (
        <section className="py-16 lg:py-20 px-variable-md">
          <div className="max-w-3xl m-auto">
            <div className="text-center">
              <h2 className="text-display-md font-serif mx-auto">Your Upcoming Sessions</h2>
            </div>

            <div className="mt-16 mx-auto">
              <div className="grid gap-y-7 lg:gap-y-8">
                {coaches.map((coach) => (
                  <>
                    {coach.pendingSessions.map((session) => (
                      <SessionCardPending
                        key={session.id}
                        name={coach.displayName}
                        coachLink={paths.coach({ slug: coach.slug })}
                        description={coach.title}
                        image={
                          <img
                            src={coach.avatarUrl}
                            alt={`Photo of ${coach.displayName}`}
                            width="100"
                            height="100"
                            className="w-[100px] h-[100px]"
                          />
                        }
                        createdDatetime={session.utcCreatedAt}
                      />
                    ))}
                    {coach.upcomingSessions.map((session) => (
                      <SessionCardUpcoming
                        key={session.id}
                        name={coach.displayName}
                        coachLink={paths.coach({ slug: coach.slug })}
                        description={coach.title}
                        image={
                          <img
                            src={coach.avatarUrl}
                            alt={`Photo of ${coach.displayName}`}
                            width="100"
                            height="100"
                            className="w-[100px] h-[100px]"
                          />
                        }
                        datetime={session.utcSessionStart}
                      />
                    ))}
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default UpcomingSessions;
