import React, { FC, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useUserDetails } from '#src/AppData';
import OpportunityNavbar from './elements/OpportunityNavbar';
import AllOpportunities from './AllOpportunities';
import Handpicked from './Handpicked';
import Bookmarked from './Bookmarked';
import LockedOpportunities from './LockedOpportunities';

const Opportunities: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useUserDetails();

  const initialSection = useMemo(() => {
    const section = searchParams.get('section') as 'all' | 'handpicked' | 'bookmarked';

    return section ?? 'all';
  }, [searchParams]);

  const [opportunitySection, setOpportunitySection] = useState<'all' | 'handpicked' | 'bookmarked'>(initialSection);

  const changeOpportunitySection = (section: 'all' | 'handpicked' | 'bookmarked') => {
    setOpportunitySection(section);
    if (section === 'all') {
      searchParams.delete('section');
      setSearchParams(searchParams);
    } else {
      setSearchParams({ section });
    }
  };

  const StepContent = useMemo(() => {
    switch (opportunitySection) {
      case 'all':
        return <AllOpportunities />;
      case 'handpicked':
        return <Handpicked />;
      case 'bookmarked':
        return <Bookmarked />;
      default:
        return null;
    }
  }, [opportunitySection]);

  if (user == null) return null;

  if (user.moderationState === 'limited') {
    return <LockedOpportunities lockType={user.rejected ? 'rejected' : 'limited'} />;
  }

  return (
    <>
      <Helmet>
        <title>Hiperpool - Jobs</title>
      </Helmet>

      <div className="grow flex flex-col max-w-8xl mx-auto lg:py-12">
        <OpportunityNavbar
          opportunitySection={opportunitySection}
          changeOpportunitySection={changeOpportunitySection}
        />
        {StepContent}
      </div>
    </>
  );
};

export default Opportunities;
