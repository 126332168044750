import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import SignInForm from './Form';
import DarkBackgroundWithPeopleLayout from '../DarkBackgroundWithPeopleLayout';

const SignIn: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hiperpool - Sign In</title>
        <meta name="description" content="Welcome back to Hiperpool. Sign in to access your personalized dashboard." />
      </Helmet>
      <DarkBackgroundWithPeopleLayout>
        <div className="w-full max-w-lg justify-start 2xs:my-16 2xs:mx-variable-lg p-10 max-2xs:py-16 max-2xs:px-variable-lg 2xs:self-start bg-gray-50 text-hpblack 2xs:rounded-lg flex flex-col gap-6 items-center">
          <SignInForm />
        </div>
      </DarkBackgroundWithPeopleLayout>
    </>
  );
};

export default SignIn;
