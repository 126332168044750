import React, { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NextHire from './elements/NextHire';
import StrategicRoles from './elements/StrategicRoles';
import RecordOfSuccess from './elements/RecordOfSuccess';
import YourSuccessIsOurSuccess from './elements/YourSuccessIsOurSuccess';
import TestimonialCarousel from './elements/TestimonialCarousel';
import WantToLearnMore from './elements/WantToLearnMore';
import EmployerHeroBanner from './elements/EmployerHeroBanner';
import PricingPlan from './elements/PricingPlan';

const EmployerIndex: FC = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Hiperpool</title>
          <meta
            name="description"
            content="Hiperpool is a specialist global recruitment firm focused on strategy, consulting, and finance professionals."
          />
        </Helmet>

        <EmployerHeroBanner />
        <RecordOfSuccess />
        <NextHire />
        <StrategicRoles />
        <PricingPlan />
        <TestimonialCarousel />
        <YourSuccessIsOurSuccess />
        <WantToLearnMore />
      </HelmetProvider>
    </>
  );
};

export default EmployerIndex;
