import * as apiGetLanguages from '#api/endpoints/onboarding/languages';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  languages = 'languages',
}

type GetApiPayload = apiGetLanguages.Type['success']['payload'];
export const useGetLanguages = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.languages],
    queryFn: async () => await apiGetLanguages.request(),
    ...options,
  });
