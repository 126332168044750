import React, { FC } from 'react';
import { useGetProfilePreferences } from './data/ProfilePreferences';
import { useUserDetails } from '#src/AppData';
import { Spinner } from '#components/Index';
import NotFound from '#pages/NotFound';
import Preferences from './Preferences';
import PreferencesLocked from './PreferencesLocked';

const PreferencesPage: FC = () => {
  const { user } = useUserDetails();
  const { data, isLoading } = useGetProfilePreferences();

  if (user == null) return null;

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  if (user.moderationState === 'limited') {
    return (
      <PreferencesLocked
        profilePreferences={data.profilePreferences}
        lockType={user.rejected ? 'rejected' : 'limited'}
      />
    );
  }

  return <Preferences profilePreferences={data.profilePreferences} />;
};

export default PreferencesPage;
