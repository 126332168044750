import React, { FC, useRef, useEffect, useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Job } from '#src/types';
import { request as apiMarkAsRead } from '#api/endpoints/talents/activity/markAsRead';
import { KeysEnum as jobsQueryKeys } from '#pages/talentPages/activity/data/JobsData';
import { LockedMessaging } from '#src/components/Index';
import JobHeader from './JobHeader';
import JobHistoryItem from './history/JobHistoryItem';
import OfferReply from './OfferReply';
import ContactedDirectly from './ContactedDirectly';
import Sidebar from '../sidebar/Sidebar';

interface JobContentProps {
  selectedJob: Job | null;
  setJobsListOpenOnMobile?: Dispatch<SetStateAction<boolean>>;
}

const JobContent: FC<JobContentProps> = ({ selectedJob, setJobsListOpenOnMobile = () => {} }) => {
  const queryClient = useQueryClient();
  const historyRef = useRef<HTMLDivElement>(null);

  const markAsRead = useCallback(async () => {
    if (selectedJob == null || !selectedJob.history.withNotification) return;

    await apiMarkAsRead({ id: selectedJob.id });
    await queryClient.invalidateQueries({
      queryKey: [jobsQueryKeys.talents, jobsQueryKeys.activity, jobsQueryKeys.jobs],
    });
  }, [queryClient, selectedJob]);

  // Scroll to the bottom of the history when the selected job changes
  useEffect(() => {
    if (historyRef.current != null) {
      historyRef.current.scrollTop = historyRef.current.scrollHeight;
    }
  }, [selectedJob]);

  // Remove the notification after the job is read by the talent
  useEffect(() => {
    void (async () => {
      await markAsRead();
    })();
  }, [selectedJob, markAsRead]);

  const replyContent = useMemo(() => {
    if (selectedJob == null) return null;

    if (selectedJob.currentStage === 'Decision') {
      return <LockedMessaging message="This role was archived." />;
    }

    if (selectedJob.currentStage === 'Pending') return null;

    if (selectedJob.currentStage === 'PutForward') return <ContactedDirectly job={selectedJob} />;

    if (selectedJob.offer.withMessages) {
      return <OfferReply job={selectedJob} />;
    }

    return <LockedMessaging message="This employer does not use Hiperpool messaging and will contact you directly." />;
  }, [selectedJob]);

  if (selectedJob == null) return null;

  return (
    <>
      <JobHeader selectedJob={selectedJob} setJobsListOpenOnMobile={setJobsListOpenOnMobile} />

      {/* Sidebar is rendered also here for mobile screen purposes  */}
      <div className="lg:hidden bg-white p-4">
        <Sidebar selectedJob={selectedJob} isLoading={false} />
      </div>

      <div className="flex flex-col grow">
        <div className="flex flex-col grow justify-between">
          <div ref={historyRef} className="overflow-y-auto lg:max-h-[calc(65dvh)] p-8">
            <ul role="list">
              {selectedJob.history.entries.map((historyItem, historyIndex) => (
                <JobHistoryItem
                  key={historyIndex}
                  {...historyItem}
                  isLast={selectedJob.history.entries.length - 1 === historyIndex}
                  companyLogoUrl={selectedJob.opportunity.companyLogoUrl}
                />
              ))}
            </ul>
          </div>

          {replyContent}
        </div>
      </div>
    </>
  );
};

export default JobContent;
