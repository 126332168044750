import { ReactQueryOptions } from '#src/types';
import { useQuery } from '@tanstack/react-query';

import * as apiGetProfileDetails from '#api/endpoints/talents/profile/profileDetails';

export enum KeysEnum {
  talents = 'talents',
  profile = 'profile',
  details = 'details',
}

type GetApiPayload = apiGetProfileDetails.Type['success']['payload'];

export const useGetProfileDetails = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.talents, KeysEnum.profile, KeysEnum.details],
    queryFn: async () => await apiGetProfileDetails.request(),
    ...options,
  });
