import React, { FC } from 'react';
import { Opportunity } from '#src/types';
import { Button, Modal, Icon, Accordion } from '#components/Index';
import { useOpportunityTeamMembersController } from './FormController';
import TeamMemberInput from './TeamMemberInput';
import TeamMemberEditableAvatar from './TeamMemberEditableAvatar';

interface PotentialColleaguesModalProps {
  opportunity: Opportunity;
  isOpen: boolean;
  closeModal: () => void;
}

const PotentialColleaguesModal: FC<PotentialColleaguesModalProps> = ({ opportunity, isOpen, closeModal }) => {
  const { form, submitForm } = useOpportunityTeamMembersController({
    opportunity,
    closeModal,
  });

  const { register, handleSubmit } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={onSubmit} className="w-full">
        <div className="flex flex-col bg-gray-50">
          <div className="pt-8 px-8">
            <div className="flex justify-around items-center pb-2">
              <div className="flex items-center gap-x-8 text-gray-500 text-sm">Opportunity</div>

              <button
                onClick={closeModal}
                className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
                aria-label="Close"
              >
                <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
              </button>
            </div>

            <div className="flex items-center justify-between">
              <h1 className="text-hpblack text-display-sm font-serif mb-4">
                Edit &apos;Potential Colleagues&apos; section
              </h1>
            </div>
          </div>

          <div className="flex gap-y-4 flex-col p-8">
            <Accordion title="Team Member 1" initiallyExpanded>
              <div className="flex flex-col md:flex-row gap-x-12 p-4 items-center">
                <TeamMemberEditableAvatar
                  opportunityId={opportunity.id}
                  teamMemberId={1}
                  avatarUrl={opportunity.teamMembersForm.teamMember1AvatarUrl}
                />
                <TeamMemberInput
                  memberNumber={1}
                  register={register}
                  setValue={form.setValue}
                  initialBackgrounds={opportunity.teamMembersForm.teamMember1Backgrounds}
                />
              </div>
            </Accordion>

            <Accordion title="Team Member 2">
              <div className="flex flex-col md:flex-row gap-x-12 p-4 items-center">
                <TeamMemberEditableAvatar
                  opportunityId={opportunity.id}
                  teamMemberId={2}
                  avatarUrl={opportunity.teamMembersForm.teamMember2AvatarUrl}
                />
                <TeamMemberInput
                  memberNumber={2}
                  register={register}
                  setValue={form.setValue}
                  initialBackgrounds={opportunity.teamMembersForm.teamMember2Backgrounds}
                />
              </div>
            </Accordion>

            <Accordion title="Team Member 3">
              <div className="flex flex-col md:flex-row gap-x-12 p-4 items-center">
                <TeamMemberEditableAvatar
                  opportunityId={opportunity.id}
                  teamMemberId={3}
                  avatarUrl={opportunity.teamMembersForm.teamMember3AvatarUrl}
                />
                <TeamMemberInput
                  memberNumber={3}
                  register={register}
                  setValue={form.setValue}
                  initialBackgrounds={opportunity.teamMembersForm.teamMember3Backgrounds}
                />
              </div>
            </Accordion>
          </div>
        </div>

        <div className="bg-white flex gap-x-8 justify-center px-8 py-6">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>

          <Button type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PotentialColleaguesModal;
