import React, { FC, useCallback, useState, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Job } from '#src/types';
import { KeysEnum as jobsQueryKeys } from '#pages/talentPages/activity/data/JobsData';
import { request as apiOfferReply } from '#api/endpoints/talents/offers/offerReply';
import { Button } from '#src/components/Index';

interface OfferReplyProps {
  job: Job;
}

const OfferReply: FC<OfferReplyProps> = ({ job }) => {
  const queryClient = useQueryClient();
  const [message, setMessage] = useState('');

  const handleReply = useCallback(async () => {
    if (job.offer.id == null) return;

    await apiOfferReply({ id: job.offer.id, reply: { content: message } });
    await queryClient.invalidateQueries({
      queryKey: [jobsQueryKeys.talents, jobsQueryKeys.activity, jobsQueryKeys.jobs],
    });

    setMessage('');
  }, [job, message, queryClient]);

  const sendMessageButtonLocked = useMemo(() => message === '', [message]);

  return (
    <div className="flex flex-col gap-y-4 p-8">
      <textarea
        className="w-full h-20 p-4 bg-white text-gray-500 border border-gray-300 rounded-md"
        placeholder="Enter your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className="flex justify-end">
        <Button size="small" disabled={sendMessageButtonLocked} onClick={handleReply}>
          Send message to employer
        </Button>
      </div>
    </div>
  );
};

export default OfferReply;
