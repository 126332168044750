import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: { slug: string };
  success: {
    post: {
      id: number;
      metaDescription: string;
      metaTitle: string;
      imageUrl: string;
      mainCategory: string;
      title: string;
      createdAt: string;
      content: string;
      locked: boolean;
      readTime: number;
      author: {
        name: string;
        avatarUrl: string;
        title: string;
      };
      relatedPosts: Array<{
        id: number;
        slug: string;
        imageUrl: string;
        mainCategory: string;
        title: string;
        truncatedContent: string;
      }>;
    };
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, `/public/posts/${params.slug}`)(params);
