import * as apiGetOpportunityDetails from '#api/endpoints/talents/opportunities/getOpportunityDetails';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { isApiError } from '#src/lib/parseFormsErrors';
import { ReactQueryOptions } from '#src/types';
import { paths } from '#pages/paths';
export enum KeysEnum {
  talents = 'talents',
  opportunities = 'opportunities',
  show = 'show',
}

type GetApiPayload = apiGetOpportunityDetails.Type['success']['payload'];
export const useGetOpportunityDetails = (id: number, options?: ReactQueryOptions<GetApiPayload>) => {
  const navigate = useNavigate();
  const isValidId = !isNaN(id);

  const query = useQuery<GetApiPayload>({
    queryKey: [KeysEnum.talents, KeysEnum.opportunities, KeysEnum.show, id],
    queryFn: async () => {
      try {
        return await apiGetOpportunityDetails.request({ id });
      } catch (e) {
        if (isApiError(e) && e.status === 403) {
          navigate(paths.talentOpportunities());
        }
        throw e;
      }
    },
    enabled: isValidId,
    ...options,
  });

  if (!isValidId) {
    return {
      data: undefined,
      isFetching: false,
      refetch: async () => {},
    };
  }

  return query;
};
