import * as apiGetCandidateDetails from '#api/endpoints/employers/candidates/candidateDetails';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employers',
  candidates = 'candidates',
  show = 'show',
}

type GetApiPayload = apiGetCandidateDetails.Type['success']['payload'];
export const useGetCandidateDetails = (id: number, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.employers, KeysEnum.candidates, KeysEnum.show, id],
    queryFn: async () => await apiGetCandidateDetails.request({ id }),
    ...options,
  });
