import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams, Link } from 'react-router-dom';
import { opportunityIdFromParams } from '#src/lib/opportunity.utils';
import { paths } from '#pages/paths';
import { useGetPotentialCandidates } from './data/PotentialCandidatesData';
import { useGetTalentPoolData } from '../shared/data/TalentPoolData';
import PotentialCandidateCard from './elements/PotentialCandidateCard';
import PotentialCandidatesFilters from './elements/Filters';
import PotentialCandidatesNavbar from './elements/PotentialCandidatesNavbar';
import { Spinner, Icon } from '#components/Index';

export const NoCandidates: FC = () => {
  return (
    <div>
      <div className="bg-white py-8 flex justify-center rounded-md">
        <div className="w-2/3 text-center">
          <Icon.EmptyBookmarked size={12} />
          <h3 className="text-display-sm font-serif text-hpblack my-4">No talent pending review</h3>
          <p className="text-sm mb-4">
            At the moment, you have no profiles pending review. If you have already reached out to candidates for this
            opportunity, you can see those candidates here{' '}
            <Link className="link" to={paths.employerInterviews()}>
              here
            </Link>
            .
          </p>
        </div>
      </div>
      <div className="flex flex-col space-y-8 mt-8">
        <div className="bg-white h-32 w-full opacity-60 rounded-md"></div>
        <div className="bg-white h-32 w-full opacity-45 rounded-md"></div>
        <div className="bg-white h-32 w-full opacity-30 rounded-md"></div>
      </div>
    </div>
  );
};

const PotentialCandidates: FC = () => {
  const [searchParams] = useSearchParams();

  const [selectedOpportunityId, setSelectedOpportunityId] = useState<number | undefined>(
    opportunityIdFromParams(searchParams)
  );

  const { data: talentPoolData, isLoading: isTalentPoolDataLoading } = useGetTalentPoolData();
  const { data: potentialCandidatesData, isLoading: isCandidatesLoading } =
    useGetPotentialCandidates(selectedOpportunityId);

  if (isCandidatesLoading || isTalentPoolDataLoading) {
    return <Spinner className="py-32" />;
  }

  if (potentialCandidatesData == null || talentPoolData == null) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Hiperpool - Talent Pool</title>
      </Helmet>
      <div className="grow flex flex-col max-w-8xl mx-auto lg:py-12">
        <PotentialCandidatesNavbar
          candidatesCount={talentPoolData.potentialCandidatesCount}
          interviewsCount={talentPoolData.interviewsCount}
        />

        <div className="flex flex-col lg:flex-row gap-x-8 px-4">
          <div className="basis-1/4">
            <PotentialCandidatesFilters
              opportunities={talentPoolData.opportunities}
              selectedOpportunityId={selectedOpportunityId}
              setSelectedOpportunityId={setSelectedOpportunityId}
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-8">
            {potentialCandidatesData.candidates.map((candidate) => (
              <PotentialCandidateCard key={candidate.id} candidate={candidate} />
            ))}

            {potentialCandidatesData.candidates.length === 0 && <NoCandidates />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PotentialCandidates;
