import React, { FC } from 'react';

import { AnimationRow, Button } from '#components/Index';
import { paths } from '#pages/paths';
import { useGetFeaturedStories } from './RecordOfSuccessData';
import { Icon } from '../../../../components/Index';
import { chunk } from 'lodash-es';

interface CardProps {
  position: string;
  logo: React.ReactNode;
  location: string;
}

const Card: FC<CardProps> = ({ position, logo, location }) => (
  <div className="grid text-center p-4 md:p-6 rounded-lg w-80 md:w-96 h-full shadow-md-dark">
    <div className="grid items-center font-serif text-display-xs/7 h-14 overflow-clip">{position}</div>
    <div className="grid place-items-center [&>*]:max-h-[4.5rem] mt-2">{logo}</div>
    <div className="flex justify-center text-gray-500 text-sm leading-tight mt-3">
      <Icon.MapPinFill size={4} className="text-green-600 mr-1" />
      {location}
    </div>
  </div>
);

const RecordOfSuccess: FC = () => {
  const { data } = useGetFeaturedStories();

  if (data == null) {
    return <></>;
  }

  return (
    <div className="bg-white pt-16 pb-20 overflow-clip">
      <h2 className="flex justify-center text-center text-display-md font-serif px-variable-sm">
        A demonstrated track record <br className="max-sm:hidden" />
        of success across roles
      </h2>
      <div className="pb-16 pt-12">
        {chunk(data?.successStories, 6).map((stories, rowIndex) => (
          <AnimationRow className="gap-5 py-5" key={`chunk-of-stories-${rowIndex}`}>
            {stories.map((story, colIndex) => (
              <Card
                key={`chunk-${rowIndex}-story-${colIndex}`}
                position={story.position}
                logo={<img src={story.companyImageUrl} loading="lazy" />}
                location={story.location}
              />
            ))}
          </AnimationRow>
        ))}
      </div>
      <div className="flex justify-center">
        <Button variant="secondary" size="large" to={paths.stories()}>
          View our Success Stories
        </Button>
      </div>
    </div>
  );
};

export default RecordOfSuccess;
