import * as apiGetOpportunityOverview from '#api/endpoints/public/getOpportunityOverviewDetails';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  talents = 'talents',
  opportunities = 'opportunities',
  show = 'show',
}

type GetApiPayload = apiGetOpportunityOverview.Type['success']['payload'];
export const useGetOpportunityOverviewData = (secretId: string, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.talents, KeysEnum.opportunities, KeysEnum.show, secretId],
    queryFn: async () => await apiGetOpportunityOverview.request({ secretId }),
    ...options,
  });
