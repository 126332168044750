import { useMemo } from 'react';
import { string, object } from 'yup';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';

import * as loginAPI from '#api/endpoints/session/login';
import { generateOriginAppUrl } from '#src/lib/generateOriginAppUrl';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import { KeysEnum as AppDataKeys } from '#src/AppData';

export interface FormValues {
  email: string;
  password: string;
}

const defaultFormValues: FormValues = {
  email: '',
  password: '',
};

const validationSchema = object().shape({
  email: string().required('Email is required'),
  password: string().trim().required('Password is required'),
});

export const useSignInController = () => {
  const [searchParams] = useSearchParams();
  const redirectPath = useMemo(() => searchParams.get('redirectUrl') ?? '', [searchParams]);
  const queryClient = useQueryClient();
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const { parseErrors, baseErrors } = useParseFormErrors<loginAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      const res = await loginAPI.request({
        talent: values,
        redirectPath,
      });
      await queryClient.invalidateQueries({
        queryKey: [AppDataKeys.global, AppDataKeys.user, AppDataKeys.details],
      });
      window.location.href = generateOriginAppUrl({ path: res.redirectUrl });
    } catch (errors) {
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm, baseErrors };
};
