import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useOpportunityEditSectionController } from './FormController';
import { RichTextInput } from '#components/Form/Index';
import { Button, Modal, Icon } from '#components/Index';

interface EditInterestingSectionModalProps {
  opportunityId: number;
  sectionTitle: string;
  initialContent: string;
  section: 'why_interesting_body' | 'about_company_body' | 'about_role_body';
  isOpen: boolean;
  closeModal: () => void;
}

const EditOpportunityContentSectionModal: FC<EditInterestingSectionModalProps> = ({
  opportunityId,
  sectionTitle,
  initialContent,
  section,
  isOpen,
  closeModal,
}) => {
  const { form, submitForm } = useOpportunityEditSectionController({
    id: opportunityId,
    initialContent,
    section,
    closeModal,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col bg-gray-50 w-full">
          <div className="pt-8 px-8">
            <div className="flex justify-around items-center pb-2">
              <div className="flex items-center gap-x-8 text-gray-500 text-sm">Opportunity</div>

              <button
                onClick={closeModal}
                className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
                aria-label="Close"
              >
                <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
              </button>
            </div>

            <div className="flex items-center justify-between">
              <h1 className="text-hpblack text-display-sm font-serif mb-4">Edit &apos;{sectionTitle}&apos; section</h1>
            </div>
          </div>

          <div className="flex gap-y-4 flex-col px-8 pb-12">
            <Controller
              name="content"
              control={control}
              render={({ field }) => (
                <RichTextInput
                  label="Content"
                  initialValue={initialContent}
                  onChange={(e) => field.onChange(e.target.value)}
                  className="rich-text-without-paragraph-margin"
                  error={errors.content?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="bg-white flex gap-x-8 justify-center px-8 py-6">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>

          <Button type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditOpportunityContentSectionModal;
