import { useQuery } from '@tanstack/react-query';

import * as apiGetStory from '#api/endpoints/stories/getStory';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  successStories = 'success-stories',
  stories = 'stories',
  show = 'show',
}

type GetApiPayload = apiGetStory.Type['success']['payload'];
export const useGetStory = (slug: string | undefined, options?: ReactQueryOptions<GetApiPayload>) => {
  if (slug == null) {
    throw Error('Slug is not provided');
  }
  return useQuery({
    queryKey: [KeysEnum.successStories, KeysEnum.stories, KeysEnum.show, slug],
    queryFn: async () => await apiGetStory.request({ slug }),
    ...options,
  });
};
