import React, { FC, useContext, createContext, ReactNode, useEffect, useState, useMemo, useCallback } from 'react';
import { StepNotFound } from './exceptions';
import { Step } from './CurrentStep';
import { Tree, TreeNode } from './Tree';

import { useTalentDataController } from './TalentDataController';
import OnboardingType from './steps/onboardingType/OnboardingType';
import Intro from './steps/preferences/Intro';
import CurrentSituation from './steps/preferences/CurrentSituation';
import TypeOfEmployment from './steps/preferences/TypeOfEmployment';
import InterestedFirms from './steps/preferences/interestedFirms/InterestedFirms';
import InterestedInStartups from './steps/preferences/interestedFirms/Startup';
import InterestedInCorporate from './steps/preferences/interestedFirms/Corporate';
import InterestedInManagementConsulting from './steps/preferences/interestedFirms/ManagementConsulting';
import InterestedInPrivateEquity from './steps/preferences/interestedFirms/PrivateEquity';
import InterestedInInvestmentBanking from './steps/preferences/interestedFirms/InvestmentBanking';
import WhereToWork from './steps/preferences/whereToWork/WhereToWork';
import WorkPolicy from './steps/preferences/WorkPolicy';
import HistoryIntro from './steps/history/Intro';
import ExperienceFirms from './steps/history/experienceFirms/ExperienceFirms';
import ExperienceInStartups from './steps/history/experienceFirms/Startup';
import ExperienceInCorporate from './steps/history/experienceFirms/Corporate';
import ExperienceInManagementConsulting from './steps/history/experienceFirms/ManagementConsulting';
import ExperienceInPrivateEquity from './steps/history/experienceFirms/PrivateEquity';
import ExperienceInInvestmentBanking from './steps/history/experienceFirms/InvestmentBanking';
import ExperienceIndustries from './steps/history/experienceFirms/ExperienceIndustries';
import ExperienceYears from './steps/history/ExperienceYears';
import Languages from './steps/history/Languages';
import EmploymentHistory from './steps/employmentHistory/EmploymentHistory';
import EmailConfirmation from './steps/EmailConfirmation';

export const steps = [
  OnboardingType,
  Intro,
  CurrentSituation,
  TypeOfEmployment,
  InterestedFirms,
  InterestedInStartups,
  InterestedInCorporate,
  InterestedInManagementConsulting,
  InterestedInPrivateEquity,
  InterestedInInvestmentBanking,
  WhereToWork,
  WorkPolicy,
  HistoryIntro,
  ExperienceFirms,
  ExperienceYears,
  ExperienceInStartups,
  ExperienceInCorporate,
  ExperienceInManagementConsulting,
  ExperienceInPrivateEquity,
  ExperienceInInvestmentBanking,
  ExperienceIndustries,
  Languages,
  EmploymentHistory,
  EmailConfirmation,
];

const StepControllerContext = createContext<
  | {
      stepTree: Tree;
      currentStepId: String;
      progress: { now: number; max: number };
      next: () => TreeNode;
      previous: () => TreeNode;
      getCurrentStep: () => Step;
      hideFullOnboardingSteps: () => void;
      showFullOnboardingSteps: () => void;
      isLimitedOnboarding: (onboardingStep: string) => boolean;
      stepLoaded: boolean;
    }
  | undefined
>(undefined);

interface Props {
  children: ReactNode;
  tree: Tree;
  listOfActiveNodes: () => TreeNode[];
  listOfAllNodes: () => TreeNode[];
  fullOnboardingStepIds: string[];
}

const stepMapper = (step: string | undefined) => {
  if (step == null) {
    return 'user-type';
  }

  // Steps from backend mapped to the frontend steps
  const stepMaps = new Map([
    ['account_type', OnboardingType.id],
    ['current_situation', CurrentSituation.id],
    ['employment_type', TypeOfEmployment.id],
    ['types_of_functions_interest', InterestedFirms.id],
    ['work_policies', WorkPolicy.id],
    ['locations_and_authorizations', WhereToWork.id],
    ['experience_timeline', ExperienceYears.id],
    ['types_of_functions_experience', ExperienceFirms.id],
    ['industry_experience', ExperienceIndustries.id],
    ['languages', Languages.id],

    ['work_experience', EmploymentHistory.id],
    ['work_experience_limited', EmploymentHistory.id],

    ['confirm_email', EmailConfirmation.id],
    ['confirm_email_limited', EmailConfirmation.id],
  ]);

  return stepMaps.get(step);
};

const StepControllerProvider: FC<Props> = ({
  children,
  tree,
  listOfActiveNodes,
  listOfAllNodes,
  fullOnboardingStepIds,
}) => {
  const { talentData } = useTalentDataController();
  const [currentNode, setCurrentNode] = useState<TreeNode>(tree.root);
  const [stepLoaded, setStepLoaded] = useState(false);

  const getIndexOfActiveNode = (node: TreeNode) => listOfActiveNodes().findIndex((n: TreeNode) => n.key === node.key);

  const hideFullOnboardingSteps = useCallback(() => {
    fullOnboardingStepIds.forEach((id) => {
      tree.setHidden(id);
    });
  }, [fullOnboardingStepIds, tree]);

  const showFullOnboardingSteps = useCallback(() => {
    fullOnboardingStepIds.forEach((id) => {
      tree.setVisible(id);
    });
  }, [fullOnboardingStepIds, tree]);

  useEffect(() => {
    if (stepLoaded) return; // Prevent infinite loop while changing the talent data

    if (talentData != null) {
      // Load the current step from the talent data
      const currentStepId = stepMapper(talentData.onboardingStep);
      const node = listOfActiveNodes().find((obj: TreeNode) => obj.key === currentStepId);

      if (node !== undefined) {
        setCurrentNode(node);
        setStepLoaded(true);
      }
    }
  }, [stepLoaded, talentData, listOfActiveNodes]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLimitedOnboarding = (onboardingStep: string) => {
    return onboardingStep.includes('limited');
  };

  // Hide the full onboarding steps if the user is in the limited onboarding
  useEffect(() => {
    if (talentData == null) return;

    if (isLimitedOnboarding(talentData.onboardingStep)) {
      hideFullOnboardingSteps();
    }
  }, [talentData, hideFullOnboardingSteps]);

  const progress = useMemo(() => {
    // We need to calculate the progress based on all nodes, not just the active ones to prevent the progress bar from jumping back
    const index = listOfAllNodes().findIndex((n: TreeNode) => n.key === currentNode.key);

    return { now: index, max: listOfAllNodes().length - 1 };
  }, [currentNode, listOfAllNodes]);

  const next = () => {
    const index = getIndexOfActiveNode(currentNode);
    if (index === -1) {
      throw new StepNotFound('Hidden step have no next step');
    }
    const nextIndex = index + 1;
    const nextStep = listOfActiveNodes()[nextIndex];
    if (nextStep == null) {
      throw new StepNotFound('There is no next step');
    }
    setCurrentNode(nextStep);
    return nextStep;
  };

  const previous = () => {
    const index = getIndexOfActiveNode(currentNode);
    let prevIndex = 0;
    let prevStep: TreeNode;

    if (index === -1) {
      if (currentNode.parent != null) {
        const parentIndex = getIndexOfActiveNode(currentNode.parent);
        prevStep = listOfActiveNodes()[parentIndex];
      } else {
        throw new StepNotFound('Parent-less hidden steps have no previous step');
      }
    } else {
      prevIndex = index - 1;
      prevStep = listOfActiveNodes()[prevIndex];
    }

    if (prevStep == null) {
      throw new StepNotFound('There is no previous step');
    }

    setCurrentNode(prevStep);
    return prevStep;
  };

  const getCurrentStep = () => {
    const currentStep = steps.find((s) => s.id === currentNode.key);
    if (currentStep == null) {
      throw new StepNotFound('Current step was not found');
    }
    return currentStep;
  };

  const value = {
    stepTree: tree,
    currentStepId: currentNode.key,
    progress,
    next,
    previous,
    getCurrentStep,
    hideFullOnboardingSteps,
    showFullOnboardingSteps,
    isLimitedOnboarding,
    stepLoaded,
  };

  return <StepControllerContext.Provider value={value}>{children}</StepControllerContext.Provider>;
};

const useStepController = () => {
  const context = useContext(StepControllerContext);

  if (context === undefined) {
    throw new Error('useStepController must be used within a StepControllerContext');
  }

  return context;
};

export { StepControllerProvider, useStepController };
