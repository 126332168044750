import React, { FC } from 'react';
import { TalentPreferences } from '#root/src/types';
import ProfileSubNavigation from '../elements/ProfileSubNavigation';
import PreferencesVisibilityInfo from './elements/PreferencesVisibilityInfo';
import CurrentSituation from './elements/CurrentSituation';
import WorkHistory from './elements/WorkHistory';
import LockedLimited from '#pages/talentPages/opportunities/index/elements/LockedLimited';
import LockedRejected from '#pages/talentPages/opportunities/index/elements/LockedRejected';

interface PreferencesLockedProps {
  lockType: 'limited' | 'rejected';
  profilePreferences: TalentPreferences;
}

const PreferencesLocked: FC<PreferencesLockedProps> = ({ lockType, profilePreferences }) => {
  const lockedComponent = lockType === 'limited' ? <LockedLimited /> : <LockedRejected />;

  return (
    <div className="grow flex flex-col max-w-6xl xl:max-w-8xl mx-auto py-6">
      <ProfileSubNavigation />
      <div className="relative max-h-[calc(80dvh)]">
        {lockedComponent}
        <div className="mt-4 px-8 2xl:px-0 blur-sm">
          <PreferencesVisibilityInfo profilePreferences={profilePreferences} />

          <h1 className="hp-black font-serif text-display-xs mb-4">My Experience</h1>
          <div className="w-full px-8 bg-white rounded-lg shadow-md-dark mb-8 divide-y-2">
            <CurrentSituation profilePreferences={profilePreferences} />

            <WorkHistory profilePreferences={profilePreferences} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesLocked;
