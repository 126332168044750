import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '#components/Index';
import { paths } from '#pages/paths';

interface Props {
  title: string;
  author: string;
  publishDate: string;
  imageUrl: string;
  readTime: number;
  category?: string;
}
const Header: FC<Props> = ({ title, author, publishDate, readTime, imageUrl, category }) => {
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="mb-12">
      <div className="flex justify-center">
        <div className="py-6 lg:py-8 max-w-screen-lg mx-variable-lg grow">
          <Link to={paths.blog()} className="large-link inline-flex items-center gap-2 mt-1">
            <Icon.ArrowLeftLine size={5} />
            Back to Insights & Resources
          </Link>
        </div>
      </div>
      <div className="px-variable-lg">
        <div className="mx-auto max-w-3xl">
          <h1 className="font-serif text-display-sm lg:text-display-md my-6">{title}</h1>
          <p className="mt-5 mb-14 text-sm text-gray-500 uppercase">
            by <span className="underline underline-offset-1">{author}</span>, {publishDate} -{' '}
            <span className="lowercase">{readTime} min read</span>
          </p>
        </div>
      </div>
      <div className="sm:px-variable-sm">
        <div className="relative sm:max-w-screen-lg mx-auto">
          <img src={imageUrl} alt="" onLoad={() => setImageLoaded(true)} loading="eager" />
          {category != null && isImageLoaded && (
            <span className="absolute top-4 mr-4 text-green-900 bg-green-200 px-2.5 py-1 rounded-r text-sm font-medium">
              {category}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
