import { ReactQueryOptions } from '#src/types';
import { useQuery } from '@tanstack/react-query';

import * as apiGetHiringDetails from '#api/endpoints/talents/hiring/hirieIndex';

export enum KeysEnum {
  talents = 'talents',
  hiring = 'hiring',
}

type GetApiPayload = apiGetHiringDetails.Type['success']['payload'];

export const useHiringDetails = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.talents, KeysEnum.hiring],
    queryFn: async () => await apiGetHiringDetails.request(),
    ...options,
  });
