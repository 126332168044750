import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

import * as apiGetListOfBundles from '#api/endpoints/public/bundleSessionPackages';

export enum KeysEnum {
  components = 'components',
  coachBundles = 'coach-bundles',
}

type GetApiPayload = apiGetListOfBundles.Type['success']['payload'];
export const useBundleList = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.components, KeysEnum.coachBundles],
    queryFn: async () => await apiGetListOfBundles.request(),
    ...options,
  });
