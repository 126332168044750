import * as apiGetWorkInterestSituationTypes from '#api/endpoints/onboarding/workInterestSituationTypes';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  workInterestSituationTypes = 'work-interest-situation',
}

type GetApiPayload = apiGetWorkInterestSituationTypes.Type['success']['payload'];
export const useGetWorkInterestSituationTypes = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.workInterestSituationTypes],
    queryFn: async () => await apiGetWorkInterestSituationTypes.request(),
    ...options,
  });
