import React, { FC, useState } from 'react';
import DOMPurify from 'dompurify';
import { Button, Icon } from '#components/Index';
import EditableSummary from './EditableSummary/EditableSummary';

interface ProfileSummaryProps {
  summary: string;
}

const ProfileSummary: FC<ProfileSummaryProps> = ({ summary }) => {
  const [editMode, setEditMode] = useState(false);

  const summaryContent = () => {
    if (editMode) {
      return <EditableSummary summary={summary} setEditMode={setEditMode} />;
    }

    if (summary == null) {
      return <p className="text-hpblack font-light text-md max-w-screen-sm">No summary added yet.</p>;
    }

    return (
      <div
        className="text-hpblack font-light text-md max-w-screen-sm rich-text rich-text-lists"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(summary) }}
      />
    );
  };

  return (
    <div className="flex flex-col-reverse flex-grow relative gap-y-4 md:flex-row md:gap-y-0">
      {!editMode && (
        <div className="text-center">
          <Button
            variant="secondary"
            size="small"
            className="md:absolute right-0 top-0"
            onClick={() => {
              setEditMode(!editMode);
            }}
          >
            <Icon.EditPencil size={3} />
            Edit
          </Button>
        </div>
      )}

      {summaryContent()}
    </div>
  );
};

export default ProfileSummary;
