import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from '#pages/paths';
import { OpportunityThumbnail } from '#src/types';
import BookmarkButton from './BookmarkButton';
import styles from './OpportunityCard.module.css';
import { Icon, Button } from '#components/Index';
import OpportunityFooterContent from './OpportunityFooterContent';

interface OpportunityCardProps {
  opportunity: OpportunityThumbnail;
  stateless?: boolean;
  handleNotInterested?: (opportunity: OpportunityThumbnail) => void;
  isClickable?: boolean;
  openArchiveModal?: () => void;
  openEditModal?: () => void;
}

const OpportunityCard: FC<OpportunityCardProps> = ({
  opportunity,
  stateless = false,
  handleNotInterested = () => {},
  isClickable = false,
  openArchiveModal = null,
  openEditModal = null,
}) => {
  const navigate = useNavigate();

  const cardStateStyles = useMemo(() => {
    if (stateless) {
      return '';
    }

    const stateType = opportunity.candidacy?.state?.type;

    switch (stateType) {
      case 'handpicked':
        return styles.cardHandpicked;
      case 'archived':
      case 'not_interested':
        return styles.cardDisabled;
      default:
        return '';
    }
  }, [stateless, opportunity]);

  const clickableCardStyles = isClickable ? 'cursor-pointer' : '';

  const onClick = () => {
    if (!isClickable) return;

    navigate(paths.talentOpportunity({ id: opportunity.id }));
  };

  const manageActionsVisible = openEditModal != null || openArchiveModal != null;

  return (
    <div className={[styles.card, cardStateStyles, clickableCardStyles].join(' ')}>
      <BookmarkButton candidacy={opportunity.candidacy} opportunityId={opportunity.id} />
      <div className="grid p-6 gap-x-8 items-start sm:flex md:gap-y-6" onClick={onClick}>
        <div className={styles.logoContainer}>
          <img
            src={opportunity.companyLogoUrl}
            alt={opportunity.companyName}
            className="mix-blend-multiply max-h-[100px] m-auto sm:m-0 object-contain hidden lg:block"
          />
        </div>

        <div className="flex flex-col gap-y-2 flex-grow">
          <div className="flex items-center gap-x-4 lg:gap-x-0">
            <div className={styles.logoContainer}>
              <img
                src={opportunity.companyLogoUrl}
                alt={opportunity.companyName}
                className="mix-blend-multiply max-h-[60px] m-auto sm:m-0 object-contain block md:max-h-[80px] lg:hidden"
              />
            </div>
            <div>
              <h3 className={styles.position}>{opportunity.position}</h3>
              <p className="text-xl text-gray-500">{opportunity.companyName}</p>
            </div>
          </div>

          <p className="text-md font-light text-gray-500">{opportunity.pitch}</p>

          <div className="flex flex-col gap-y-1">
            <div className="flex items-start text-md text-gray-500 gap-x-2">
              <div className="flex py-[2px]">
                <Icon.MapPinFill size={5} aria-hidden="true" className="text-green-500" />
              </div>
              <div>{opportunity.location}</div>
            </div>

            <div className="flex items-center text-md text-gray-500 gap-x-2">
              <div className="flex py-[2px]">
                <Icon.BankCardFill size={5} aria-hidden="true" className="text-green-500" />
              </div>
              {opportunity.compensation}
            </div>
          </div>
          {manageActionsVisible && (
            <div className="flex mt-4 justify-start gap-x-4">
              {openEditModal != null && (
                <Button variant="secondary" size="small" onClick={openEditModal}>
                  <Icon.EditPencil size={3} />
                  Edit section
                </Button>
              )}

              {openArchiveModal != null && !opportunity.archived && (
                <Button variant="secondary" size="small" onClick={openArchiveModal}>
                  <Icon.ArchiveLine size={4} />
                  Archive
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      {!stateless && opportunity.candidacy != null && (
        <OpportunityFooterContent opportunity={opportunity} handleNotInterested={handleNotInterested} />
      )}
    </div>
  );
};

export default OpportunityCard;
