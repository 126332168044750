import React, { FC, useMemo } from 'react';
import { TalentLanguage } from '#src/types';
import ProfileBadge from '../../elements/ProfileBadge';

const proficiencyOrder = ['Native', 'Fluent', 'Business', 'Conversational', 'Basic'];

interface GroupedLanguagesProps {
  languages: TalentLanguage[];
}

const GroupedLanguages: FC<GroupedLanguagesProps> = ({ languages }) => {
  const sortedLanguages = useMemo(() => {
    return [...languages].sort((a, b) => {
      const proficiencyDiff = proficiencyOrder.indexOf(a.proficiency) - proficiencyOrder.indexOf(b.proficiency);

      if (proficiencyDiff !== 0) return proficiencyDiff;

      return a.language.localeCompare(b.language);
    });
  }, [languages]);

  return (
    <div className="flex flex-wrap gap-x-4 gap-y-4">
      {sortedLanguages.map((language) => (
        <ProfileBadge key={`${language.language}-${language.proficiency}`} withoutIcon>
          {language.language} - {language.proficiency}
        </ProfileBadge>
      ))}
    </div>
  );
};

export default GroupedLanguages;
