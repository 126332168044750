import React, { FC, useCallback, ChangeEvent } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { request as apiUpdateTeamMemberAvatar } from '#api/endpoints/employers/opportunities/updateTeamMemberAvatar';
import { KeysEnum as opportunityQueryKeys } from '../../data/EmployerOpportunityData';

export interface TeamMemberEditableAvatarProps {
  opportunityId: number;
  teamMemberId: number;
  avatarUrl: string;
}

const TeamMemberEditableAvatar: FC<TeamMemberEditableAvatarProps> = ({ opportunityId, teamMemberId, avatarUrl }) => {
  const queryClient = useQueryClient();

  const handleFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0];

      if (selectedFile == null) return;

      try {
        await apiUpdateTeamMemberAvatar({ opportunityId, teamMemberId, avatar: selectedFile });
        void queryClient.invalidateQueries({
          queryKey: [
            opportunityQueryKeys.employers,
            opportunityQueryKeys.opportunities,
            opportunityQueryKeys.show,
            opportunityId,
          ],
        });
        createSuccessToast({ title: 'Team Member avatar' });
      } catch {
        createErrorToast({ title: 'Team Member avatar' });
      }
    },
    [opportunityId, teamMemberId, queryClient]
  );

  return (
    <label>
      <input type="file" className="hidden" onChange={handleFileChange} />
      <div className="w-[120px] h-[120px] rounded-full overflow-hidden flex justify-center items-center group hover:cursor-pointer relative">
        <img
          height="120"
          width="120"
          className="min-w-full min-h-full relative"
          src={avatarUrl}
          alt="Team member avatar"
        />
        <div className="absolute inset-0 bg-[#101A2C66] opacity-0 group-hover:opacity-100 transition-opacity"></div>

        <div className="text-sm px-6 py-3 inline-flex items-center justify-center gap-2 rounded-full leading-tight font-semibold outline-white ring-2 ring-inset ring-white text-white absolute invisible group-hover:visible">
          Change
        </div>
      </div>
    </label>
  );
};

export default TeamMemberEditableAvatar;
