import * as apiGetEmployerOpportunities from '#api/endpoints/employers/opportunities/getOpportunities';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employer',
  opportunities = 'opportunities',
}

type GetApiPayload = apiGetEmployerOpportunities.Type['success']['payload'];
export const useGetEmployerOpportunitiesData = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.employers, KeysEnum.opportunities],
    queryFn: async () => await apiGetEmployerOpportunities.request(),
    ...options,
  });
