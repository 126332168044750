import React, { FC } from 'react';
import { CoachCardLarge } from '#components/Index';
import { paths } from '#root/src/pages/paths';
import { Coach } from '#root/src/types';

const CoachesList: FC<{ coaches: Coach[] | undefined }> = ({ coaches }) => {
  return (
    <div className="mt-16 my-auto">
      {coaches != null && coaches.length > 0 ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-7 lg:gap-x-8 gap-y-12 lg:gap-y-14 justify-items-center max-sm:[&>*]:max-w-[380px]">
          {coaches.map((coach) => (
            <CoachCardLarge
              key={coach.id}
              image={
                <img
                  src={coach.avatarUrl}
                  alt={`Photo of ${coach.displayName}`}
                  width="100"
                  height="100"
                  className="w-[100px] h-[100px]"
                />
              }
              name={coach.displayName}
              description={coach.title}
              location={coach.region}
              companyLogos={coach.companyImages.map((img) => (
                <img
                  key={img.name}
                  src={img.imageUrl}
                  alt={img.name}
                  className="max-h-[40px] m-auto sm:m-0 object-contain"
                />
              ))}
              link={paths.coach({ slug: coach.slug })}
              rating={coach.ratings.average}
              reviewCount={coach.ratings.count}
              price={coach.pricing}
            />
          ))}
        </div>
      ) : (
        <div className="text-center">
          <p className="uppercase text-gray-500 font-semibold text-xs leading-tight">
            There are no coaches for given filters
          </p>
        </div>
      )}
    </div>
  );
};

export default CoachesList;
