import { ReactQueryOptions } from '#src/types';
import { useQuery } from '@tanstack/react-query';

import * as apiGetPreselectedOpportunityFilters from '#api/endpoints/talents/opportunities/preselectedOpportunityFilters';

export enum KeysEnum {
  talents = 'talents',
  opportunities = 'opportunities',
  preselectedOpportunityFilters = 'preselected-opportunity-filters',
}

type GetApiPayload = apiGetPreselectedOpportunityFilters.Type['success']['payload'];

export const useGetPreselectedOpportunityFilters = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.talents, KeysEnum.opportunities, KeysEnum.preselectedOpportunityFilters],
    queryFn: async () => await apiGetPreselectedOpportunityFilters.request(),
    ...options,
  });
