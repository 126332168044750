import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { TalentProfile } from '#src/types';

import { KeysEnum as ProfileDetailsKeysEnum } from '#src/pages/talentPages/profileAndPreferences/profile/data/ProfileDetails';
import * as updateProfileDetailsAPI from '#api/endpoints/talents/profile/updateProfileDetails';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';

const queryKeys = [ProfileDetailsKeysEnum.talents, ProfileDetailsKeysEnum.profile, ProfileDetailsKeysEnum.details];

export interface FormValues extends FieldValues {
  firstName: string;
  lastName: string;
  phone: string | null;
  currentEmploymentId: number | null;
  currentEducationId: number | null;
  location: string;
}

const defaultFormValues = (profile: TalentProfile): FormValues => ({
  firstName: profile.firstName,
  lastName: profile.lastName,
  currentEmploymentId: profile.currentEmploymentId,
  currentEducationId: profile.currentEducationId,
  location: profile.location,
  phone: profile.phone,
});

const validationSchema = object().shape({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  location: string().required('Location is required'),
});

interface ControllerProps {
  profile: TalentProfile;
  closeEditMode: () => void;
}

export const useProfileDetailsController = ({ profile, closeEditMode }: ControllerProps) => {
  const queryClient = useQueryClient();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(profile),
  });

  const { parseErrors } = useParseFormErrors<updateProfileDetailsAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await updateProfileDetailsAPI.request({
        profile: values,
      });
      closeEditMode();
      void queryClient.invalidateQueries({
        queryKey: queryKeys,
      });
      createSuccessToast({ title: 'Profile details updated successfully' });
    } catch (errors) {
      createErrorToast({ title: 'Failed to update profile details' });
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm };
};
