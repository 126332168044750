import { ReactQueryOptions } from '#src/types';
import { useQuery } from '@tanstack/react-query';

import * as apiGetOpportunityCounters from '#api/endpoints/talents/opportunities/opportunityCounters';

export enum KeysEnum {
  opportunityCounters = 'opportunity-counters',
}

type GetApiPayload = apiGetOpportunityCounters.Type['success']['payload'];

export const useGetOpportunityCounters = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.opportunityCounters],
    queryFn: async () => await apiGetOpportunityCounters.request(),
    ...options,
  });
