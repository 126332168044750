import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Block from './Block';
import Content from './Content';
import Header from './Header';
import { useGetPostDetails } from './PostDetailsData';
import RelatedPosts from './RelatedPosts';
import NotFound from '#pages/NotFound';
import { Spinner } from '#components/Index';

const Post: FC = () => {
  const { slug = '' } = useParams();
  const { data, isLoading } = useGetPostDetails(slug);

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  return (
    <>
      <Helmet>
        <title>{data.post.metaTitle}</title>
        <meta name="description" content={data.post.metaDescription} />
      </Helmet>
      <Header
        title={data.post.title}
        readTime={data.post.readTime}
        author={data.post.author.name}
        publishDate={data.post.createdAt}
        imageUrl={data.post.imageUrl}
        category={data.post.mainCategory}
      />
      <Content content={data.post.content} author={data.post.author} />
      {data.post.locked && <Block />}
      {data.post.relatedPosts.length > 0 && <RelatedPosts posts={data.post.relatedPosts} />}
    </>
  );
};

export default Post;
