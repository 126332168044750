import * as apiGetEmploymentBranches from '#api/endpoints/onboarding/employmentBranches';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  experienceBranches = 'experience-branches',
}

type GetApiPayload = apiGetEmploymentBranches.Type['success']['payload'];
export const useGetInterestedFirmsOptions = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.experienceBranches],
    queryFn: async () => await apiGetEmploymentBranches.request(),
    ...options,
  });
