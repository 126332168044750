import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';
import * as apiGetListOfPosts from '#api/endpoints/posts/getList';

export enum KeysEnum {
  blog = 'blog',
  posts = 'posts',
  list = 'list',
}

type GetApiPayload = apiGetListOfPosts.Type['success']['payload'];
export const useGetPosts = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>({
    queryKey: [KeysEnum.blog, KeysEnum.posts, KeysEnum.list],
    queryFn: async () => await apiGetListOfPosts.request(),
    placeholderData: keepPreviousData,
    ...options,
  });
