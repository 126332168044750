import React, { FC, useContext, createContext, ReactNode, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { TalentData, OnboardingStep, Errors } from '#root/src/types';
import { useGetTalentData } from './TalentData';
import * as apiUpdateOnboardingTalent from '#api/endpoints/onboarding/updateOnboardingTalent';
import * as apiSendConfirmationEmail from '#api/endpoints/onboarding/sendConfirmationEmail';
import * as apiFinishOnboarding from '#api/endpoints/onboarding/finishOnboarding';
import { KeysEnum as ProfileDetailsKeys } from '#pages/talentPages/profileAndPreferences/profile/data/ProfileDetails';
import { KeysEnum as ProfilePreferencesKeys } from '#pages/talentPages/profileAndPreferences/preferences/data/ProfilePreferences';

const profileDetailsQueryKeys = [ProfileDetailsKeys.talents, ProfileDetailsKeys.profile, ProfileDetailsKeys.details];
const profilePreferencesQueryKeys = [
  ProfilePreferencesKeys.talents,
  ProfilePreferencesKeys.profile,
  ProfilePreferencesKeys.preferences,
];

interface TalentDataControllerContextType {
  talentData: TalentData | undefined;
  isTalentDataLoading: boolean;
  setTalentData: React.Dispatch<React.SetStateAction<TalentData | undefined>>;
  updateTalentData: (talentData: TalentData, onboardingStep: OnboardingStep) => Promise<any>;
  sendConfirmationEmail: () => Promise<any>;
  finishOnboarding: () => Promise<any>;
  getError: (fieldName: string) => string;
  refetch: () => void;
}

const TalentDataControllerContext = createContext<TalentDataControllerContextType | undefined>(undefined);

interface Props {
  children: ReactNode;
}

const TalentDataControllerProvider: FC<Props> = ({ children }) => {
  const { data, refetch, isLoading: isTalentDataLoading } = useGetTalentData();
  const [talentData, setTalentData] = useState<TalentData | undefined>(data);
  const [stepErrors, setStepErrors] = useState<Errors>({});
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data != null) {
      setTalentData(data);
    }
  }, [data]);

  const updateTalentData = async (talentData: TalentData, onboardingStep: OnboardingStep) => {
    try {
      const newTalentData = await apiUpdateOnboardingTalent.request({ ...talentData, onboardingStep });
      setTalentData(newTalentData);

      // Invalidate profile details and preferences queries to ensure the updated data is fetched in scenarios where limited user
      // decides to complete their profile and preferences via the onboarding flow.
      void queryClient.invalidateQueries({
        queryKey: profileDetailsQueryKeys,
      });
      void queryClient.invalidateQueries({
        queryKey: profilePreferencesQueryKeys,
      });

      return { success: true };
    } catch (error) {
      console.error(error);

      const typedError = error as { errors: Errors };
      setStepErrors(typedError.errors);

      return { success: false };
    }
  };

  const sendConfirmationEmail = async () => {
    const emailRedirectPath = searchParams.get('redirect_path');

    try {
      if (emailRedirectPath === null) {
        await apiSendConfirmationEmail.request();
      } else {
        await apiSendConfirmationEmail.request({ redirectPath: emailRedirectPath });
      }

      return { success: true };
    } catch (errors) {
      console.error(errors);
      return { success: false, payload: errors };
    }
  };

  const finishOnboarding = async () => {
    try {
      const result = await apiFinishOnboarding.request();

      window.location.href = result.redirectUrl;
    } catch (errors) {
      console.error(errors);
    }
  };

  const getError = (fieldName: string): string => {
    const errorField = stepErrors?.[fieldName];
    const error = errorField != null ? errorField[0] : undefined;

    return error ?? '';
  };

  const value = {
    talentData,
    isTalentDataLoading,
    setTalentData,
    updateTalentData,
    sendConfirmationEmail,
    finishOnboarding,
    getError,
    refetch,
  };

  return <TalentDataControllerContext.Provider value={value}>{children}</TalentDataControllerContext.Provider>;
};

const useTalentDataController = () => {
  const context = useContext(TalentDataControllerContext);

  if (context === undefined) {
    throw new Error('useTalentDataController must be used within a TalentDataControllerContext');
  }

  return context;
};

export { TalentDataControllerProvider, useTalentDataController };
