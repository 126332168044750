import React, { FC } from 'react';
import { TextInput } from '#components/Form/Index';
import { Button } from '#components/Index';
import { useLinkedinUrlController } from './FormController';

interface EditableLinkedinProps {
  setEditMode: (editMode: boolean) => void;
  linkedinUrl: string | null;
}

const EditableLinkedin: FC<EditableLinkedinProps> = ({ linkedinUrl, setEditMode }) => {
  const { form, submitForm } = useLinkedinUrlController({
    linkedinUrl: linkedinUrl ?? '',
    closeEditMode: () => setEditMode(false),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-y-2">
      <div className="flex gap-4">
        <TextInput className="md:min-w-96" label="" {...register('linkedinUrl')} error={errors.linkedinUrl?.message} />
        <div className="mt-1">
          <Button type="submit">Save</Button>
        </div>
      </div>
      <p className="text-gray-700">
        Go to your LinkedIn profile from{' '}
        <a href="https://www.linkedin.com/profile/view" target="_blank" rel="noreferrer" className="text-blue-500">
          here
        </a>
      </p>
    </form>
  );
};

export default EditableLinkedin;
