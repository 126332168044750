import * as apiGetEmployerOpportunity from '#api/endpoints/employers/opportunities/getOpportunityDetails';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employers',
  opportunities = 'opportunities',
  show = 'show',
}

type GetApiPayload = apiGetEmployerOpportunity.Type['success']['payload'];
export const useGetEmployerOpportunityData = (id: number, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.employers, KeysEnum.opportunities, KeysEnum.show, id],
    queryFn: async () => await apiGetEmployerOpportunity.request({ id }),
    ...options,
  });
