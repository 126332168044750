import { ReactQueryOptions } from '#src/types';
import { useQuery } from '@tanstack/react-query';

import * as apiGetDummyJobsList from '#api/endpoints/talents/activity/dummyJobsList';

export enum KeysEnum {
  talents = 'talents',
  activity = 'activity',
  jobs = 'dummy-jobs',
}

type GetApiPayload = apiGetDummyJobsList.Type['success']['payload'];

export const useGetDummyJobsList = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.talents, KeysEnum.activity, KeysEnum.jobs],
    queryFn: async () => await apiGetDummyJobsList.request(),
    ...options,
  });
