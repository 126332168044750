import React, { FC } from 'react';
import { paths } from '#pages/paths';
import { generateOriginAppUrl } from '#root/src/lib/generateOriginAppUrl';
import { Icon, Button } from '#src/components/Index';

const LockedLimited: FC = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center z-50">
      <div className="w-full max-w-2xl bg-white p-10 max-2xs:py-16 max-2xs:px-variable-lg 2xs:rounded-lg grid gap-6 max-2xs:border-b shadow-xl border-2 border-gray-200">
        <Icon.LockedCircle size={12} className="mx-auto" aria-hidden="true" />
        <h3 className="font-serif text-display-xs text-center">Ready to access job opportunities?</h3>

        <p className="text-gray-800 text-sm text-center">
          By answering a few questions about your experience and preferences, you’ll unlock access to job opportunities
          and help our team find hand-picked roles that are a great match for you.
        </p>

        <div className="flex justify-center">
          <a href={generateOriginAppUrl({ path: paths.talentStartOnboarding() })}>
            <Button size="large">
              Complete your onboarding <Icon.ArrowRightLine size={4} aria-hidden="true" />
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LockedLimited;
