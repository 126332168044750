import React, { FC } from 'react';
import { paths } from '#pages/paths';
import { Icon, Modal, Button } from '#components/Index';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ContactedDirectlyConfirmationModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="p-16 pt-8 flex flex-col items-center w-full gap-8">
        <button
          onClick={onClose}
          className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline self-center"
          aria-label="Close"
        >
          <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
        </button>

        <div className="w-full grid gap-6 text-sm font-medium">
          <div className="flex flex-col gap-y-3 text-center">
            <h2 className="text-display-xs font-serif hp-black text-center">Thank you for letting us know</h2>

            <p className="hp-black font-light text-md">
              This candidate will now be moved to the{' '}
              <a className="underline text-blue-500" href={paths.employerInterviews()}>
                Interviewing section
              </a>{' '}
              of your talent pool. Please keep your Client Success Manager up to date on progress and next steps, so we
              can continue to support you throughout the process.
            </p>
          </div>

          <div className="flex items-center justify-center">
            <Button variant="primary" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactedDirectlyConfirmationModal;
