import React, { FC } from 'react';
import { Opportunity } from '#src/types';
import { useOpportunityHeaderController } from './FormController';
import { TextInput, TextArea } from '#components/Form/Index';
import { Button, Modal, Icon } from '#components/Index';

interface EditHeaderModalProps {
  opportunity: Opportunity;
  isOpen: boolean;
  closeModal: () => void;
}

const EditHeaderModal: FC<EditHeaderModalProps> = ({ opportunity, isOpen, closeModal }) => {
  const { form, submitForm } = useOpportunityHeaderController({
    id: opportunity.id,
    position: opportunity.position,
    pitch: opportunity.pitch ?? '',
    jobLocalisation: opportunity.location,
    compensation: opportunity.compensation,
    closeModal,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={onSubmit} className="w-full">
        <div className="flex flex-col bg-gray-50">
          <div className="pt-8 px-8">
            <div className="flex justify-around items-center pb-2">
              <div className="flex items-center gap-x-8 text-gray-500 text-sm">Opportunity</div>

              <button
                onClick={closeModal}
                className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
                aria-label="Close"
              >
                <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
              </button>
            </div>

            <div className="flex items-center justify-between">
              <h1 className="text-hpblack text-display-sm font-serif mb-4">Edit header</h1>
            </div>
          </div>

          <div className="flex gap-y-4 flex-col px-8 pb-12">
            <TextInput
              label="Opportunity title"
              type="text"
              error={errors.position?.message}
              {...register('position')}
            />

            <TextArea label="Description" rows={5} error={errors.pitch?.message} {...register('pitch')} />

            <TextInput
              label="Location"
              type="text"
              error={errors.jobLocalisation?.message}
              {...register('jobLocalisation')}
            />

            <TextInput
              label="Compensation"
              type="text"
              error={errors.compensation?.message}
              {...register('compensation')}
            />
          </div>
        </div>

        <div className="bg-white flex gap-x-8 justify-center px-8 py-6">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>

          <Button type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditHeaderModal;
