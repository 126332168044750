import { ReactQueryOptions } from '#src/types';
import { useQuery } from '@tanstack/react-query';

import * as apiGetDummyOpportunities from '#api/endpoints/talents/opportunities/dummyOpportunities';

export enum KeysEnum {
  talents = 'talents',
  opportunities = 'opportunities',
  dummyOpportunities = 'dummy-opportunities',
}

type GetApiPayload = apiGetDummyOpportunities.Type['success']['payload'];

export const useGetDummyOpportunities = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.talents, KeysEnum.opportunities, KeysEnum.dummyOpportunities],
    queryFn: async () => await apiGetDummyOpportunities.request(),
    ...options,
  });
