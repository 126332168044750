import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './elements/Header';

const Referrals: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Hiperpool - Referrals</title>
      </Helmet>
      <Header />
    </div>
  );
};

export default Referrals;
