import * as apiGetListOfFeaturedPosts from '#api/endpoints/posts/getFeaturedList';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  homepage = 'homepage',
  interviewPrep = 'interview-prep',
  featuredPosts = 'featured-posts',
}

type GetApiPayload = apiGetListOfFeaturedPosts.Type['success']['payload'];
export const useGetFeaturedPosts = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.homepage, KeysEnum.interviewPrep, KeysEnum.featuredPosts],
    queryFn: async () => await apiGetListOfFeaturedPosts.request(),
    ...options,
  });
