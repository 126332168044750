import * as apiGetListOfStories from '#api/endpoints/stories/getList';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  successStories = 'success-stories',
  stories = 'stories',
  list = 'list',
}

type GetApiPayload = apiGetListOfStories.Type['success']['payload'];
export const useGetStories = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery({
    queryKey: [KeysEnum.successStories, KeysEnum.stories, KeysEnum.list],
    queryFn: async () => await apiGetListOfStories.request(),
    placeholderData: keepPreviousData,
    ...options,
  });
