import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { opportunityIdFromParams } from '#src/lib/opportunity.utils';
import { EmployerInterviewingCandidate } from '#src/types';
import { useGetTalentPoolData } from '../shared/data/TalentPoolData';
import { useGetInterviewingCandidatesData, KeysEnum as candidatesQueryKeys } from './data/InterviewingCandidatesData';
import { useGetArchivedCandidatesData } from './data/ArchivedCandidatesData';
import { request as apiMarkOfferAsRead } from '#api/endpoints/employers/offers/markAsRead';
import { ErrorPage } from '#pages/root/ErrorPage';
import CandidateFilters from './elements/CandidateFilters';
import CandidateSidebar from './elements/candidate/CandidateSidebar';
import CandidatesList from './elements/CandidatesList';
import InterviewContent from './elements/InterviewContent';

const Interviews: FC = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const [candidates, setCandidates] = useState<EmployerInterviewingCandidate[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<EmployerInterviewingCandidate | null>(null);
  const [candidatesListOpenOnMobile, setCandidatesListOpenOnMobile] = useState(true); // On mobile we want to display navigation OR content
  const [selectedFilter, setSelectedFilter] = useState<string>('interviewing');
  const [opportunityId, setOpportunityId] = useState<number | undefined>(opportunityIdFromParams(searchParams));

  const { data: talentPoolData, isLoading: isTalentPoolDataLoading } = useGetTalentPoolData();
  const { data: interviewingData, isLoading: isInterviewingLoading } = useGetInterviewingCandidatesData(opportunityId);
  const { data: archivedData, isLoading: isArchivedLoading } = useGetArchivedCandidatesData(opportunityId);

  const markAsRead = async (candidate: EmployerInterviewingCandidate) => {
    if (!candidate.history.withNotification) return;

    await apiMarkOfferAsRead({ id: candidate.offer.id });
    await queryClient.invalidateQueries({
      queryKey: [candidatesQueryKeys.employers, candidatesQueryKeys.talentPool, candidatesQueryKeys.candidates],
    });
  };

  const displayCandidateDetails = async (candidate: EmployerInterviewingCandidate | null) => {
    if (candidate == null) return;

    setSelectedCandidate(candidate);
    setCandidatesListOpenOnMobile(false);
    await markAsRead(candidate);
  };

  const sortAndSetCandidates = useCallback(() => {
    if (archivedData == null || interviewingData == null) return;

    const candidatesData = selectedFilter === 'archived' ? archivedData.candidates : interviewingData.candidates;
    const sortedCandidates = candidatesData.sort((a, b) => {
      const aDate = a.history?.lastActivityAt != null ? new Date(a.history.lastActivityAt) : new Date(0);
      const bDate = b.history?.lastActivityAt != null ? new Date(b.history.lastActivityAt) : new Date(0);

      return bDate.getTime() - aDate.getTime();
    });

    setCandidates(sortedCandidates);
  }, [archivedData, interviewingData, selectedFilter]);

  const isLoading = useMemo(() => {
    return isInterviewingLoading || isTalentPoolDataLoading || isArchivedLoading;
  }, [isInterviewingLoading, isTalentPoolDataLoading, isArchivedLoading]);

  const candidateListContainerClasses = useMemo(() => {
    const base = 'bg-white border-r border-gray-300 min-h-full-content flex-col 2xl:basis-1/5';
    const additional = candidatesListOpenOnMobile ? 'flex' : 'hidden lg:flex';

    return `${base} ${additional}`;
  }, [candidatesListOpenOnMobile]);

  const mainContentClasses = useMemo(() => {
    const base = 'flex-col lg:basis-3/5 xl:basis-4/6';
    const additional = candidatesListOpenOnMobile ? 'hidden lg:flex' : 'flex';

    return `${base} ${additional}`;
  }, [candidatesListOpenOnMobile]);

  // Initial selection
  useEffect(() => {
    if (archivedData == null || interviewingData == null || selectedCandidate != null) return;

    const candidateIdFromSearchParams = searchParams.get('candidateId');

    // If a candidateId is present in the URL and exists in the data, select it and properly set the filter/candidates list
    if (candidateIdFromSearchParams != null) {
      const candidateID = parseInt(candidateIdFromSearchParams);

      const candidateFromInterviews = interviewingData.candidates.find((candidate) => candidate.id === candidateID);
      const candidateFromArchived = archivedData.candidates.find((candidate) => candidate.id === candidateID);

      searchParams.delete('candidateId');
      window.history.replaceState({}, '', window.location.pathname);

      if (candidateFromInterviews != null) {
        setSelectedFilter('interviewing');
        sortAndSetCandidates();
        return;
      }

      if (candidateFromArchived != null) {
        setSelectedFilter('archived');
        sortAndSetCandidates();
        return;
      }
    }

    sortAndSetCandidates();
    setSelectedCandidate(candidates[0]);
  }, [
    archivedData,
    interviewingData,
    selectedFilter,
    searchParams,
    selectedCandidate,
    candidates,
    sortAndSetCandidates,
  ]);

  // Update the selected candidate after the data is re-fetched / filter section changes
  useEffect(() => {
    if (archivedData == null || interviewingData == null || selectedCandidate == null) return;

    sortAndSetCandidates();

    const updatedCandidate = candidates.find((candidate) => candidate.id === selectedCandidate.id);
    const firstCandidate = candidates[0];

    setSelectedCandidate(updatedCandidate ?? firstCandidate);
  }, [archivedData, interviewingData, selectedFilter, selectedCandidate, candidates, sortAndSetCandidates]);

  if (!isLoading && (talentPoolData == null || interviewingData == null)) {
    return <ErrorPage className="min-h-full flex flex-col justify-center" />;
  }

  return (
    <>
      <Helmet>
        <title>Hiperpool - Interviews</title>
      </Helmet>
      <div className="flex flex-col lg:flex-row">
        <div className={candidateListContainerClasses}>
          <div className="px-4 lg:hidden">
            <h3 className="text-display-xs font-serif mt-4">Interviews</h3>
          </div>

          <CandidateFilters
            opportunities={talentPoolData?.opportunities}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            opportunityId={opportunityId}
            setOpportunityId={setOpportunityId}
          />
          <CandidatesList candidates={candidates} displayCandidateDetails={displayCandidateDetails} />
        </div>

        <main className={mainContentClasses}>
          <InterviewContent
            selectedCandidate={selectedCandidate}
            setCandidatesListOpenOnMobile={setCandidatesListOpenOnMobile}
            selectedFilter={selectedFilter}
            isLoading={isLoading}
          />
        </main>

        <div className="bg-white border-l border-gray-300 p-4 flex-col hidden lg:flex lg:basis-1/5 xl:basis-1/6">
          <CandidateSidebar selectedCandidate={selectedCandidate} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Interviews;
