import React, { FC, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Job } from '#src/types';
import { request as apiContactedDirectly } from '#api/endpoints/talents/opportunities/replies/contactedDirectly';
import { KeysEnum as jobsQueryKeys } from '#pages/talentPages/activity/data/JobsData';
import { Button } from '#src/components/Index';

interface ContactedDirectlyProps {
  job: Job;
}

const ContactedDirectly: FC<ContactedDirectlyProps> = ({ job }) => {
  const queryClient = useQueryClient();

  const handleContactedDirectly = useCallback(async () => {
    if (job.currentStage !== 'PutForward') return;

    await apiContactedDirectly({ opportunityId: job.opportunity.id });
    await queryClient.invalidateQueries({
      queryKey: [jobsQueryKeys.talents, jobsQueryKeys.activity, jobsQueryKeys.jobs],
    });
  }, [queryClient, job]);

  return (
    <div className="p-8">
      <div className="p-4 flex justify-between items-center gap-x-4 rounded-md bg-[#F1F1F1]">
        <p className="text-gray-500 font-light">
          Has the employer already reached out? Let us know so we can share interview prep and insights.
        </p>
        <Button size="small" onClick={handleContactedDirectly}>
          I’m already in touch
        </Button>
      </div>
    </div>
  );
};

export default ContactedDirectly;
